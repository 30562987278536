<template>

    <div class="user-section">

        <span :class="{ 'active-menu-item': isUserProfilePath, 'menu-item': !isUserProfilePath }" @click="goToUpdateUser()"> <el-icon class="icon"><User/></el-icon> Meu perfil </span>
        <span class="menu-item" @click="logout()"> <el-icon class="icon"><Close/></el-icon> Sair </span>

    </div>

</template>

<script>

    import { USER_PROFILE_PATH } from '@/constants/RoutesConstants';

    export default {
        name: 'user-section',
        props: {
            logout: Function,
            goToUpdateUser: Function
        },
        computed: {
            isUserProfilePath() {
                return this.$route.path === USER_PROFILE_PATH;
            }
        }
    }

</script>

<style lang="scss" scoped>

    .user-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        font-weight: bold;
        color: white !important;
        background-color: $default-layout-color;
        border-bottom: 0.5px solid $default-primary-title-text-color;
        border-left: 0.5px solid $default-primary-title-text-color;
        border-right: 0.5px solid $default-primary-title-text-color;
        z-index: 3000;
        position: fixed;
        margin-top: 15px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    
    .el-menu--horizontal .el-menu .el-menu-item {
        background-color: $default-layout-color;
        color: $default-header-text-color;
    }

    .el-menu--horizontal .el-menu .el-menu-item:hover {
        background-color: $default-layout-color !important;
    }
    
    .menu-item {
        color: $default-primary-title-text-color;
        cursor: pointer;
        padding: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .menu-item:hover {
        color: white !important;
    }

    .active-menu-item {
        color: white !important;
        padding: 5px;
    }

    .icon {
        margin-right: 5px;
    }

    @media screen and (max-width: 1024px) {

        .user-section {
            margin-right: 100px;
            padding: 5px;
            width: 13%;
        }

        .active-menu-item {
            font-size: 9px;
        }

        .menu-item {
            font-size: 9px;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .user-section {
            min-width: 50px;
            margin-left: 50px;
            padding: 10px;
        }

        .active-menu-item {
            font-size: 12px;
        }

        .menu-item {
            font-size: 12px;
        }

    }

</style>
