<template>

    <div class="pending-order-section">

        <h3>
            PENDENTES
            <el-tooltip class="box-item" effect="dark" content="Últimas ordens pendentes criadas da comunidade" placement="top">
                <el-icon class="info-icon"><InfoFilled/></el-icon>
            </el-tooltip>
        </h3>
        <div class="filter-collapse">

            <el-collapse>

                <el-collapse-item name="1">

                    <template #title>
                        <span class="filter-class collapse-border"> Filtros </span>
                    </template>

                    <div class="tags">
                        <span> Categoria: </span>
                        <div class="tags-block">
                        <el-select v-if="companyTypeList.length > 0" v-model="selectedCompanyType" @change="changeCompanyTypeFilter()" class="m-2 types-tags" placeholder="Selecione" size="small">
                            <el-option v-for="companyType in companyTypeList" :key="companyType" :label="getCompanyTypeText(companyType)" :value="companyType"/>
                        </el-select>
                        <div class="selected-types">
                            <el-tag v-for="selectedType in filter.selectedCompanyTypes" :key="selectedType" class="mx-1 tag" closable :disable-transitions="false" @close="removeCompanyTypeFromList(selectedType)">
                                {{ getCompanyTypeText(selectedType) }}
                            </el-tag>
                        </div>
                        </div>
                    </div>

                    <div class="tags">
                        <span> Operação: </span>
                        <div class="tags-block">
                        <el-select v-if="typeList.length > 0" v-model="selectedType" @change="changeTypeFilter()" class="m-2 types-tags" placeholder="Selecione" size="small">
                            <el-option v-for="companyType in typeList" :key="companyType" :label="getTypeText(companyType)" :value="companyType"/>
                        </el-select>
                        <div class="selected-types">
                            <el-tag v-for="selectedType in filter.selectedTypes" :key="selectedType" class="mx-1 tag" closable :disable-transitions="false" @close="removeTypeFromList(selectedType)">
                                {{ getTypeText(selectedType) }}
                            </el-tag>
                        </div>
                        </div>
                    </div>
                    
                    <div class="dates">
                        <span> Data de execução </span>
                        <div>
                        <el-date-picker v-model="filter.range" type="daterange" unlink-panels format="DD/MM/YYYY" range-separator="até" start-placeholder="Data de criação" end-placeholder="Data de execução" size="medium"/>
                        </div>
                    </div>

                </el-collapse-item>

            </el-collapse>

        </div>
        <div class="filter-buttons">
            <button @click="clearFilters()" class="clear-button"> Limpar </button>
            <button @click="getOrders()" class="search-button"> Buscar </button>
        </div>
        <div class="pending-order-section-table">

            <table v-loading="tableLoading" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">

                <thead>
                    <tr>
                        <th> Usuário </th>
                        <th> Ativo </th>
                        <th> Categoria </th>
                        <th> Operação </th>
                        <th> Quantidade </th>
                        <th> Preço </th>
                        <th> Total </th>
                        <th> Data da criação </th>
                    </tr>
                </thead>
                <tbody v-if="pendingOrdersItems.length > 0">

                    <tr v-for="order in pendingOrdersItems" :key="order">

                        <td>
                            <div class="image-name">
                                <el-avatar v-if="order.photoUrl !== null" :size="logoWidth" :src="order.photoUrl" class="avatar"/>
                                <span style="cursor: initial;"> @{{ order.username }} </span>
                            </div>
                        </td>

                        <td @click="goToCompanyDetails(order.company)">
                            <div class="image-name">
                                <img v-if="order.company.imageUrl != null" style="margin-right: 10px" :src="order.company.imageUrl" width="30" height="20">
                                <span> {{ order.company.name }} </span>
                            </div>
                        </td>

                        <td>
                            <span :class="order.typeClassName"> {{ getCompanyType(order.company.type) }} </span>
                        </td>

                        <td>
                            <span :class="order.orderTypeClassName"> {{ getAction(order.type) }} </span>
                        </td>

                        <td>
                            <span> {{ getFormattedAmount(order) }} </span>
                        </td>

                        <td>
                            <span> {{ getOrderPriceValue(order) }} </span>
                        </td>

                        <td>
                            <span> {{ getOrderTotalValue(order) }} </span>
                        </td>

                        <td>
                            <span> {{ getDateTime(order.createdAt) }} </span>
                        </td>

                    </tr>

                </tbody>

            </table>

        </div>

        <div v-if="pendingOrdersItems.length === 0 && !tableLoading">
            <EmptyResult></EmptyResult>
        </div>

        <div v-if="pendingOrdersItems.length > 0" class="pagination-footer">

            <div class="pagination">
                <el-select v-model="pagination.limit" @change="getOrders()" class="pagination-select">

                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>

                </el-select>
                <el-pagination layout="prev, pager, next" :total="pagination.total" :page-size="pagination.limit" :current-page="page" @current-change="setPage"/>
            </div>

            <div class="number-register">
                {{ getNumberOfRegisterText() }}
            </div>

        </div>
        
    </div>

</template>

<script>

    import EmptyResult from '@/component/common/EmptyResult';
    import OrderStatus from '@/constants/OrderStatus';
    import OrderType from '@/constants/OrderType';
    import CompanyType from '@/constants/CompanyType';
    import { DEFAULT_STOCK_DECIMAL, COMMA, DOT, BLANK_PAGE_ARG } from '@/constants/AppConstants';
    import { COMPANY_DETAILS_PATH_WITHOUT_PARAM } from '@/constants/RoutesConstants';
    import { getCommunityOrders } from '@/http/bff/transaction-bff-service';
    import { getTotalValue, getFormattedValue } from '@/util/MoneyUtils';

    export default {
        name: 'pending-order-section',
        components: { EmptyResult },
        props: {
        },
        data() {
            return {
                OrderStatus,
                OrderType,
                selectedType: null,
                selectedCompanyType: null,
                orders: [],
                tableLoading: false,
                pagination: {
                    offset: 0,
                    limit: 5,
                    total: 0,
                    sorts: [
                        "executedAt###desc",
                        "type###asc",
                        "name###asc"
                    ]
                },
                options: [{
                    value: '5',
                    label: '5'
                }, {
                    value: '10',
                    label: '10'
                }, {
                    value: '20',
                    label: '20'
                }],
                filter: {
                    selectedTypes: [],
                    selectedStatus: [ OrderStatus.PENDING.value ],
                    selectedCompanyTypes: [],
                    startDateAt: null,
                    endDateAt: null,
                    range: []
                },
                dollarValue: null
            }
        },
        created() {
            this.getOrders();
        },
        methods: {
            setDatesFilter() {
                this.filter.startDateAt = this.filter.range.length >= 1 ? this.filter.range[0] : null;
                this.filter.endDateAt = this.filter.range.length >= 2 ? this.filter.range[1] : null;
            },
            getOrders() {
                this.tableLoading = true;
                this.setDatesFilter();
                getCommunityOrders(this.pagination.offset, this.pagination.limit, this.pagination.sorts, this.filter).then(response => {
                    this.orders = response.data.orders;
                    this.pagination = response.data.pagination;
                    this.dollarValue = response.data.dollarValue;
                    this.tableLoading = false;
                }).catch(() => this.tableLoading = false);
                
            },
            setPage(page) {
                this.page = page;
                this.pagination.offset = (this.page - 1) * this.pagination.limit;
                this.getOrders();
            },
            getNumberOfRegisterText() {

                const offset = this.pagination.offset + 1;
                const limit = this.pagination.limit + this.pagination.offset <= this.pagination.total ? this.pagination.limit + this.pagination.offset : this.pagination.total;

                return offset + " - " + limit + " de " + this.pagination.total + " ordens";

            },
            getFormattedAmount(order) {

                if(order.company.type !== null && order.company.type === CompanyType.STOCK.value) {
                return order.amount.toFixed(DEFAULT_STOCK_DECIMAL).replace(DOT, COMMA);
                }

                return order.amount;

            },
            getOrderPriceValue(order) {

                if(order.company !== null && order.company.type !== null) {
                const locale = CompanyType[order.company.type].locale;
                return getFormattedValue(order.price, locale);
                }

                return "-";

            },
            getOrderTotalValue(order) {

                if(order.company.type !== null) {

                    const total = parseFloat(getTotalValue(order.amount, order.price));
                    const locale = CompanyType[order.company.type].locale;

                    return getFormattedValue(total, locale);

                }

                return "-";

            },
            getDateTime(dateTime) {

                if(dateTime !== undefined && dateTime !== null) {
                    var date = new Date(dateTime);
                    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
                }

                return null;

            },
            goToCompanyDetails(company) {
                const formattedUrl = COMPANY_DETAILS_PATH_WITHOUT_PARAM + "/" + company.name.toLowerCase();
                window.open(formattedUrl, BLANK_PAGE_ARG).focus();
            },
            changeCompanyTypeFilter() {
                this.filter.selectedCompanyTypes.push(this.selectedCompanyType);
                this.selectedCompanyType = null;
            },
            changeTypeFilter() {
                this.filter.selectedTypes.push(this.selectedType);
                this.selectedType = null;
            },
            getCompanyTypeText(type) {
                return type === null ? "Todos" : CompanyType[type].description;
            },
            getTypeText(type) {
                return type === null ? "Todos" : OrderType[type].description;
            },
            removeCompanyTypeFromList(type) {

                const typeIndex = this.filter.selectedCompanyTypes.findIndex(typeFromList => typeFromList === type);
                if(typeIndex >= 0) {
                    this.filter.selectedCompanyTypes.splice(typeIndex, 1);
                    this.selectedCompanyType = null;
                }

            },
            removeTypeFromList(type) {

                const typeIndex = this.filter.selectedTypes.findIndex(typeFromList => typeFromList === type);
                if(typeIndex >= 0) {
                    this.filter.selectedTypes.splice(typeIndex, 1);
                    this.selectedType = null;
                }

            },
            clearFilters(){
                this.filter = {
                    selectedTypes: [],
                    selectedStatus: [ OrderStatus.PENDING.value ],
                    selectedCompanyTypes: [],
                    startDateAt: null,
                    endDateAt: null,
                    range: []
                };
            },
            getCompanyType(type) {
                return CompanyType[type].description;
            },
            getAction(type) {
                return OrderType[type].description;
            }
        },
        computed: {
            isPhoneScreenWidth() {
                return window.screen.width < 1024;
            },
            pendingOrdersItems() {
                this.orders.forEach(order => {
                    order.typeClassName = CompanyType[order.company.type].className;
                    order.orderTypeClassName = OrderType[order.type].className;
                });
                return this.orders;
            },
            logoWidth() {
                return this.isPhoneScreenWidth ? "15" : "15";
            },
            companyTypeList() {
                let typeList = Object.keys(CompanyType);
                return typeList.filter(type => !this.filter.selectedCompanyTypes.includes(type));
            },
            typeList() {
                let typeList = Object.keys(OrderType);
                return typeList.filter(type => !this.filter.selectedTypes.includes(type));
            }
        }
    }

</script>

<style lang="scss" scoped>

    .pending-order-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .pending-order-section-table {
        width: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: auto;
        max-width: 100% !important;
    }

    .number-register {
        display: flex;
        flex-direction: row;
        margin: 1px;
        margin-top: 15px;
        text-align: center;
        float: right;
        color: gray;
    }

    .pagination-footer {
        width: 100%;
    }

    .image-name {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .avatar {
        margin-right: 5px;
        cursor: initial;
    }

    .tags {
        width: 100%;
    }

    .tags-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    .types-tags {
        flex-direction: row;
    }

    .dates {
        margin: 10px;
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
        width: 70%;
        text-align: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    .filter-collapse {
        width: 100%;
    }

    .filter-buttons {
        width: 100%;
        margin-bottom: 30px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    @media screen and (max-width: 1024px) {

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .pending-order-section {
            margin-left: 20px;
        }

    }

</style>
