<template>

  <div class="community-home-view">

    <h1> Página inicial </h1>
    <section-row :isFirstSection="true">
      <community-home-section/>
    </section-row>
   
  </div>

</template>

<script>

  import SectionRow from '@/component/common/SectionRow';
  import CommunityHomeSection from '@/section/community/home/CommunityHomeSection';

  export default {
    name: 'community-home-view',
    components: { SectionRow, CommunityHomeSection },
    data() {
      return {
      }
    },
    methods: {
    },
    created() {
    }
  }

</script>

<style lang="scss" scoped>

  .community-home-view {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .back-button {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

</style>