<template>
    
    <div v-if="companyDetails.name !== null" class="company-details-section">

        <h3 style="margin-top: 0px;">
            INFORMAÇÕES
        </h3>
        <h3 style="margin-bottom: 10px;">
            Categoria
        </h3>
        <div class="company-info">
            <span class="info-value" :class="getCompanyTypeClassName(companyDetails.type)"> {{ CompanyType[companyDetails.type].description }} </span>
        </div>
        <div class="company-infos">

            <div v-if="companyDetails.summaryProfile !== null && companyDetails.summaryProfile.address !== null" class="company-summary-profile">

                <h3 style="margin-bottom: 10px;">
                    Resumo
                </h3>

                <div v-if="companyDetails.summaryProfile.address !== null" class="company-info">
                    <span class="info-title"> Endereço: </span>
                    <span class="info-value"> {{ companyDetails.summaryProfile.address }} </span>
                </div>

                <div v-if="companyDetails.summaryProfile.complement !== null" class="company-info">
                    <span class="info-title"> Complemento: </span>
                    <span class="info-value"> {{ companyDetails.summaryProfile.complement }} </span>
                </div>

                <div v-if="companyDetails.summaryProfile.city !== null" class="company-info">
                    <span class="info-title"> Cidade: </span>
                    <span class="info-value"> {{ companyDetails.summaryProfile.city }} </span>
                </div>

                <div v-if="companyDetails.summaryProfile.state !== null" class="company-info">
                    <span class="info-title"> Estado: </span>
                    <span class="info-value"> {{ companyDetails.summaryProfile.state }} </span>
                </div>

                <div v-if="companyDetails.summaryProfile.zip !== null" class="company-info">
                    <span class="info-title"> Código postal: </span>
                    <span class="info-value"> {{ companyDetails.summaryProfile.zip }} </span>
                </div>

                <div v-if="companyDetails.summaryProfile.country !== null" class="company-info">
                    <span class="info-title"> País: </span>
                    <span class="info-value"> {{ companyDetails.summaryProfile.country }} </span>
                </div>
                
                <div v-if="companyDetails.summaryProfile.website !== null" class="company-info">
                    <span class="info-title"> Site: </span>
                    <span class="info-value info-site" @click="goToWebSite(companyDetails.summaryProfile.website)"> {{ companyDetails.summaryProfile.website }} </span>
                </div>

                <div v-if="companyDetails.summaryProfile.industry !== null" class="company-info">
                    <span class="info-title"> Tipo de indústria: </span>
                    <span class="info-value"> {{ companyDetails.summaryProfile.industry }} </span>
                </div>

                <div v-if="companyDetails.summaryProfile.sector !== null" class="company-info">
                    <span class="info-title"> Setor: </span>
                    <span class="info-value"> {{ companyDetails.summaryProfile.sector }} </span>
                </div>

                <div v-if="companyDetails.summaryProfile.fullTimeEmployees !== null" class="company-info">
                    <span class="info-title"> Número de colaboradores: </span>
                    <span class="info-value"> {{ companyDetails.summaryProfile.fullTimeEmployees }} </span>
                </div>

            </div>

            <div v-if="companyDetails.defaultKeyStatistics !== null && companyDetails.defaultKeyStatistics.enterpriseValue !== null" class="company-key-statistics">

                <h3 style="margin-bottom: 10px;">
                    Estatísticas
                </h3>
                <div v-if="companyDetails.defaultKeyStatistics.enterpriseValue !== null" class="company-info">
                    <span class="info-title"> Valor da empresa: </span>
                    <span class="info-value"> {{ getFormattedValue(companyDetails.defaultKeyStatistics.enterpriseValue) }} </span>
                </div>

                <div v-if="companyDetails.defaultKeyStatistics.beta !== null" class="company-info">
                    <span class="info-title"> Volatilidade: </span>
                    <span class="info-value"> {{ companyDetails.defaultKeyStatistics.beta.toFixed(2).replace(".", ",") + "%" }} </span>
                </div>

                <div v-if="companyDetails.defaultKeyStatistics.floatShares !== null" class="company-info">
                    <span class="info-title"> Ações em circulação: </span>
                    <span class="info-value"> {{ companyDetails.defaultKeyStatistics.floatShares }} </span>
                </div>

                <div v-if="companyDetails.defaultKeyStatistics.netIncomeToCommon !== null" class="company-info">
                    <span class="info-title"> Lucro líquido: </span>
                    <span class="info-value"> {{ getFormattedValue(companyDetails.defaultKeyStatistics.netIncomeToCommon) }} </span>
                </div>

                <div v-if="companyDetails.defaultKeyStatistics.trailingEps !== null" class="company-info">
                    <span class="info-title"> Lucro por ação (12M): </span>
                    <span class="info-value"> {{ getFormattedValue(companyDetails.defaultKeyStatistics.trailingEps) }} </span>
                </div>

                <div v-if="companyDetails.defaultKeyStatistics.forwardEps !== null" class="company-info">
                    <span class="info-title"> Lucro por ação (próx. 12M): </span>
                    <span class="info-value"> {{ getFormattedValue(companyDetails.defaultKeyStatistics.forwardEps) }} </span>
                </div>

            </div>
            
        </div>

        <div>

            <h3>
               HISTÓRICO
            </h3>
            <div class="date-filter">
                <el-select v-model="selectedDateInterval" @change="changeData()" class="m-2" placeholder="Selecione um intervalo" size="small">
                    <el-option v-for="dateIntervalOption in dateIntervalOptions" :key="dateIntervalOption" :label="dateIntervalMap[dateIntervalOption].label" :value="dateIntervalOption"/>
                </el-select>
                <span v-if="areNotCurrenciesFromToday" class="day-info"> Referente ao dia {{ getLastCurrencyDateFormatted }} </span>
            </div>
            <div v-if="companyDetails.historicalDataPriceDetails.length > 0" class="statistics">

                <div class="statistic-item">
                    <span style="margin-bottom: 5px;"> <b> ABERTURA </b> </span>
                    <el-tag :key="companyDetails.name" class="mx-1 current-currency-tag table-tag currency-tag" :disable-transitions="false">
                        <span> {{ getFormattedOpenValue }} </span>
                    </el-tag>
                </div>

                <div class="statistic-item">
                    <span style="margin-bottom: 5px;"> <b> {{ currentMarketValueLabel }} </b> </span>
                    <el-tag :key="companyDetails.name" class="mx-1 current-currency-tag table-tag currency-tag" :disable-transitions="false">
                        <span> {{ getFormattedCurrentValue }} </span>
                    </el-tag>
                </div>

                <div class="statistic-item">
                    <span style="margin-bottom: 5px;"> <b> BAIXA </b> </span>
                    <el-tag :key="companyDetails.name" class="mx-1 table-tag currency-tag" :disable-transitions="false">
                        <span> {{ getFormattedMinValue }} </span>
                    </el-tag>
                </div>

                <div class="statistic-item">
                    <span style="margin-bottom: 5px;"> <b> ALTA </b> </span>
                    <el-tag :key="companyDetails.name" class="mx-1 table-tag currency-tag" :disable-transitions="false">
                        <span> {{ getFormattedMaxValue }} </span>
                    </el-tag>
                </div>

                <div class="statistic-item">
                    <span style="margin-bottom: 5px;"> <b> RENDIMENTO </b> </span>
                    <el-tag :key="companyDetails.name" class="mx-1 table-tag currency-tag" :disable-transitions="false">
                        <el-icon v-if="getValuation < 0" class="under-limit"><CaretBottom/></el-icon>
                        <el-icon v-if="getValuation >= 0" class="above-limit"><CaretTop/></el-icon>
                        <span :class="getValuationClassName(getValuation)"> {{ getFormattedValuation }} </span>
                        <span :class="getValuationClassName(getValuation)" class="type-percentage"> {{ getFormattedValuationPercentage }} </span>
                    </el-tag>
                </div>

            </div>

            <div v-loading="barLoading" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" class="line">
                <Line v-if="companyDetails.historicalDataPriceDetails.length > 0" :data="getData" :options="options"/>
                
            </div>
            <div v-if="showTableInfo" class="table-info">
                <table-information :bgcolor="'red'" :bcolor="'red'" :text="'Alerta enviado'"/>
            </div>

        </div>
  
    </div>
  
  </template>

<script>

    import {
        Chart as ChartJS,
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        Filler
    } from 'chart.js'
    import { Line } from 'vue-chartjs'
    import { HOME_PATH } from '@/constants/RoutesConstants';
    import { getCompanyDetails } from '@/http/bff/company-bff-service';
    import { getFormattedValue } from '@/util/MoneyUtils';
    import { DEFAULT_LOADING_SVG, BLANK_PAGE_ARG } from '@/constants/AppConstants';
    import CompanyType from '@/constants/CompanyType';
    import TableInformation from '@/component/common/TableInformation';

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        Filler
    );

    export default {
        name: 'company-details-section',
        components: { Line, TableInformation },
        props: {
            name: String
        },
        data() {
            return {
                companyDetails: {
                    name: null,
                    type: null,
                    currentMarketValue: null,
                    historicalDataPriceDetails: [],
                    dividendDetails: [],
                    defaultKeyStatistics: {
                        enterpriseValue: null,
                        forwardPE: null,
                        beta: null,
                        profitMargins: null,
                        floatShares: null,
                        bookValue: null,
                        priceToBook: null,
                        earningsQuarterlyGrowth: null,
                        netIncomeToCommon: null,
                        trailingEps: null,
                        forwardEps: null,
                        pegRatio: null,
                        enterpriseToRevenue: null,
                        lastSplitFactor: null,
                        lastSplitDate: null
                    },
                    summaryProfile: {
                        address: null,
                        complement: null,
                        city: null,
                        state: null,
                        zip: null,
                        country: null,
                        website: null,
                        industry: null,
                        industryKey: null,
                        industryDisp: null,
                        sector: null,
                        sectorKey: null,
                        sectorDisp: null,
                        longBusinessSummary: null,
                        fullTimeEmployees: null,
                        companyOfficers: []
                    }
                },
                dateIntervalMap: {
                    DAY: {
                        value: "DAY",
                        label: "HOJE",
                        description: " hoje"
                    },
                    WEEK: {
                        value: "WEEK",
                        label: "SEMANA",
                        description: " na semana"
                    },
                    MONTH: {
                        value: "MONTH",
                        label: "30 DIAS",
                        description: " em 30 dias"
                    },
                    HALF_YEAR: {
                        value: "HALF_YEAR",
                        label: "6 MESES",
                        description: " em 6 meses"
                    },
                    YEAR: {
                        value: "YEAR",
                        label: "1 ANO",
                        description: " em 1 ano"
                    }
                },
                dateIntervalOptions: [
                    "DAY",
                    "WEEK",
                    "MONTH",
                    "HALF_YEAR",
                    "YEAR"
                ],
                previousSelectedDateInterval: "DAY",
                selectedDateInterval: "DAY",
                loadingSvg: DEFAULT_LOADING_SVG,
                barLoading: false,
                data: {
                    labels: [ ],
                    datasets: [
                        {
                            label: "Variação",
                            color: "blue",
                            backgroundColor: [ ],
                            data: [ ]
                        }
                    ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    elements:{
                        point:{
                            borderWidth: 0,
                            pointRadius: 5,
                            backgroundColor: 'rgba(0,0,0,0)'
                        }
                    }
                },
                CompanyType,
                alertSent: false
            }
        },
        mounted() {
            const companyName = this.companyDetails.name != null ? this.companyDetails.name + " - " : "";
            document.title = companyName.toUpperCase() + "Personal Invest";
        },
        created() {
            this.getCompanyDetails();
        },
        methods: {
            goToWebSite(url) {
                window.open(url, BLANK_PAGE_ARG);
            },
            getValuationClassName(value) {
                return value >= 0 ? 'above-limit' : "under-limit";
            },
            getCompanyTypeClassName(type) {
                return CompanyType[type].className;
            },
            getCompanyDetails() {
                getCompanyDetails(this.name.toUpperCase(), this.selectedDateInterval.toUpperCase())
                .then(response => {
                    this.companyDetails = response.data;
                    this.changeData();
                    this.emitter.emit('setCompanyDetails', this.companyDetails);
                    this.emitter.emit('setCompanyDetailsDividends', this.companyDetails);
                }).catch(() => this.$router.push({ path: HOME_PATH }));
            },
            getFormattedValue(value) {
                const locale = CompanyType[this.companyDetails.type].locale;
                return getFormattedValue(value, locale);
            },
            arrayMin(arr) {
                return arr.reduce(function (p, v) {
                    return ( p < v ? p : v );
                });
            },
            arrayMax(arr) {
                return arr.reduce(function (p, v) {
                    return ( p > v ? p : v );
                });
            },
            changeData() {

                if((this.selectedDateInterval === this.dateIntervalMap.DAY.value && this.previousSelectedDateInterval !== this.dateIntervalMap.DAY.value) ||
                    this.selectedDateInterval !== this.dateIntervalMap.DAY.value && this.previousSelectedDateInterval === this.dateIntervalMap.DAY.value) {
                    this.getCompanyDetails();
                }
                this.previousSelectedDateInterval = this.selectedDateInterval;

                this.data = {
                    labels: [ ],
                    datasets: [
                        {
                            label: "",
                            color: "#03a94e",
                            backgroundColor: [ ],
                            data: [ ]
                        }
                    ]
                };
                this.alertSent = false;
                this.companyDetailsInFilterRange.historicalDataPriceDetails.forEach((price, index) => {

                    if(this.selectedDateInterval === this.dateIntervalMap.DAY.value) {

                        const label = new Date(price.date * 1000).toLocaleTimeString().substring(0, 5);
                        const priceValue = index === 0 ? price.open : price.close;
                        this.setDataInfo(label, priceValue, price.alertSent);

                    } else {

                        const startHourAndMinutesByType = this.companyDetails.type === CompanyType.STOCK.value ? " 9:30" : " 10:00";
                        const date = new Date(price.date * 1000);
                        const openLabel = date.toLocaleDateString() + startHourAndMinutesByType;
                        const closeLabel = date.toLocaleDateString() + " 18:00";
                        this.setDataInfo(openLabel, price.open, false);
                        this.setDataInfo(closeLabel, price.close, false);

                    }
                    
                });

            },
            setDataInfo(label, price, alertSent) {

                this.data.labels.push(label);
                this.data.datasets[0].label = "Preço";
                this.data.datasets[0].data.push(price);

                const length = this.data.datasets[0].data.length;
                this.data.datasets[0].fill = true;

                if(alertSent === true) {
                    this.alertSent = true;
                    this.data.datasets[0].backgroundColor[length - 1] = "red";
                } else {
                    this.data.datasets[0].backgroundColor[length - 1] = "#03a94e";
                }

            },
            isInTypeRange(date) {
                return ((this.companyDetails.type === CompanyType.STOCK.value && date.getHours() >= 9) ||
                        this.companyDetails.type !== CompanyType.STOCK.value && date.getHours() >= 10) && 
                        date.getHours() <= 18;
            }
        },
        computed: {
            companyDetailsInFilterRange() {

                let endDate = new Date();
                let startDate = new Date();
                if(this.selectedDateInterval == this.dateIntervalMap.DAY.value && this.companyDetails.historicalDataPriceDetails.length > 0) {

                    const lastDayWithPricesIndex = this.companyDetails.historicalDataPriceDetails.length - 1;
                    const lastDayWithPrices = this.companyDetails.historicalDataPriceDetails[lastDayWithPricesIndex];
                    const lastDayWithPricesDate = new Date(lastDayWithPrices.date * 1000);
                    if(lastDayWithPricesDate.getDay() !== startDate.getDay()) {
                        startDate = lastDayWithPricesDate;
                    }

                } else if(this.selectedDateInterval == this.dateIntervalMap.WEEK.value) {
                    startDate.setDate(endDate.getDate() - 7);
                }
                 else if(this.selectedDateInterval == this.dateIntervalMap.MONTH.value) {
                    startDate.setMonth(endDate.getMonth() - 1);
                } else if(this.selectedDateInterval == this.dateIntervalMap.HALF_YEAR.value) {
                    startDate.setMonth(endDate.getMonth() - 6);
                } else if(this.selectedDateInterval == this.dateIntervalMap.YEAR.value) {
                    startDate.setMonth(endDate.getMonth() - 12);
                }
                startDate.setHours(0);
                startDate.setMinutes(0);
                startDate.setSeconds(0);
                const historicalDataPriceDetailsInRange = this.companyDetails.historicalDataPriceDetails.filter(price => {
                    const date = new Date(price.date * 1000);
                    return this.isInTypeRange(date) &&
                            date.getTime() >= startDate && 
                            date.getTime() <= endDate;
                });

                return {
                    name: this.companyDetails.name,
                    type: this.companyDetails.type,
                    currentMarketValue: this.companyDetails.currentMarketValue,
                    historicalDataPriceDetails: historicalDataPriceDetailsInRange,
                    dividendDetails: this.companyDetails.dividendDetails,
                    defaultKeyStatistics: this.companyDetails.defaultKeyStatistics
                }
                
            },
            getFormattedMinValue() {

                if(this.companyDetailsInFilterRange.historicalDataPriceDetails.length > 0) {

                    const pricesCloses = this.companyDetailsInFilterRange.historicalDataPriceDetails.map(price => price.close);
                    const filteredPricesCloses = pricesCloses.filter(price => price !== undefined && price !== null);
                    const minValue = this.arrayMin(filteredPricesCloses);

                    return this.getFormattedValue(minValue);

                }

                return "-";

            },
            getFormattedMaxValue() {

                if(this.companyDetailsInFilterRange.historicalDataPriceDetails.length > 0) {

                    const pricesCloses = this.companyDetailsInFilterRange.historicalDataPriceDetails.map(price => price.close);
                    const filteredPricesCloses = pricesCloses.filter(price => price !== undefined && price !== null);
                    const maxValue = this.arrayMax(filteredPricesCloses);

                    return this.getFormattedValue(maxValue);

                }

                return "-";

            },
            getValuation() {

                if(this.companyDetailsInFilterRange.historicalDataPriceDetails.length > 0) {

                    const lastPriceIndex = this.companyDetailsInFilterRange.historicalDataPriceDetails.length - 1;
                    const firstOpen = this.companyDetailsInFilterRange.historicalDataPriceDetails[0].open;
                    const lastClose = this.companyDetailsInFilterRange.historicalDataPriceDetails[lastPriceIndex].close;

                    if(firstOpen !== null && firstOpen > 0 && lastClose !== null && lastClose > 0) {
                        return lastClose - firstOpen;                     
                    }

                    return 0;

                }

                return 0;

            },
            getFormattedValuation() {

                if(this.companyDetailsInFilterRange.historicalDataPriceDetails.length > 0) {

                    const lastPriceIndex = this.companyDetailsInFilterRange.historicalDataPriceDetails.length - 1;
                    const firstOpen = this.companyDetailsInFilterRange.historicalDataPriceDetails[0].open;
                    const lastClose = this.companyDetailsInFilterRange.historicalDataPriceDetails[lastPriceIndex].close;

                    if(firstOpen !== null && firstOpen > 0 && lastClose !== null && lastClose > 0) {

                        const difference = lastClose - firstOpen;
                        const formattedDifference = this.getFormattedValue(difference);

                        return formattedDifference;                        

                    }

                    return "-";

                }

                return "-";

            },
            getFormattedValuationPercentage() {

                if(this.companyDetailsInFilterRange.historicalDataPriceDetails.length > 0) {

                    const lastPriceIndex = this.companyDetailsInFilterRange.historicalDataPriceDetails.length - 1;
                    const firstOpen = this.companyDetailsInFilterRange.historicalDataPriceDetails[0].open;
                    const lastClose = this.companyDetailsInFilterRange.historicalDataPriceDetails[lastPriceIndex].close;

                    if(firstOpen !== null && firstOpen > 0 && lastClose !== null && lastClose > 0) {

                        const percentage = (lastClose * 100)/firstOpen;
                        const formattedPercentageDifference = (percentage - 100).toFixed(2).replace(".", ",") + "%";

                        return formattedPercentageDifference;                        

                    }

                    return "-";

                }

                return "-";

            },
            getFormattedCurrentValue() {

                if(this.companyDetailsInFilterRange.historicalDataPriceDetails.length > 0) {

                    const lastPriceIndex = this.companyDetailsInFilterRange.historicalDataPriceDetails.length - 1;
                    const lastClose = this.companyDetailsInFilterRange.historicalDataPriceDetails[lastPriceIndex].close;

                    if(lastClose !== null && lastClose > 0) {
                        return this.getFormattedValue(lastClose);              
                    }

                    return "-";

                }

                return "-";

            },
            getFormattedOpenValue() {

                if(this.companyDetailsInFilterRange.historicalDataPriceDetails.length > 0) {

                    const firstOpen = this.companyDetailsInFilterRange.historicalDataPriceDetails[0].open;
                    if(firstOpen !== null && firstOpen > 0) {
                        return this.getFormattedValue(firstOpen);
                    }

                    return "-";

                }

                return "-";

            },
            getData() {
                return this.data;
            },
            currentMarketValueLabel() {
                return this.selectedDateInterval === this.dateIntervalMap.DAY.value ? "PREÇO ATUAL" : "FECHAMENTO";
            },
            areNotCurrenciesFromToday() {

                if(this.selectedDateInterval === this.dateIntervalMap.DAY.value && this.companyDetails.historicalDataPriceDetails.length > 0) {

                    const now = new Date();
                    const lastDayWithPricesIndex = this.companyDetails.historicalDataPriceDetails.length - 1;
                    const lastDayWithPrices = this.companyDetails.historicalDataPriceDetails[lastDayWithPricesIndex];
                    const lastDayWithPricesDate = new Date(lastDayWithPrices.date * 1000);

                    return lastDayWithPricesDate.getDay() !== now.getDay();

                }

                return false;

            },
            getLastCurrencyDateFormatted() {

                if(this.companyDetails.historicalDataPriceDetails.length > 0) {

                    const lastDayWithPricesIndex = this.companyDetails.historicalDataPriceDetails.length - 1;
                    const lastDayWithPrices = this.companyDetails.historicalDataPriceDetails[lastDayWithPricesIndex];
                    const date = new Date(lastDayWithPrices.date * 1000);
                    const yyyy = date.getFullYear();
                    let mm = date.getMonth() + 1;
                    let dd = date.getDate();

                    if (dd < 10) dd = '0' + dd;
                    if (mm < 10) mm = '0' + mm;

                    return dd + '/' + mm + '/' + yyyy;

                }

                return "";

            },
            showTableInfo() {
                return this.selectedDateInterval === this.dateIntervalMap.DAY.value && this.alertSent === true;
            }
        }
    }
  
</script>
  
<style lang="scss" scoped>

    .company-details-section {
        display: flex;
        flex-direction: column;
    }

    .statistics {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 100px;
    }

    .statistic-item {
        width: 33%;
        height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .current-currency-tag {
        box-shadow:  inset 0px 0px 7px 3px $default-primary-tag-bg-color;
        background-color: $default-primary-tag-bg-color !important;
        color: "#03a94e" !important;
    }

    .date-filter {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .company-infos {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .company-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 5px;
        margin-left: 2px;
    }

    .info-title {
        font-weight: bold;
        text-align: left;
    }

    .info-value {
        margin-left: 2px;
    }

    .day-info {
        font-weight: bold;
        margin-top: 5px;
        margin-left: 10px;
        font-size: 10px;
        font-style: italic;
    }

    .type-percentage {
        margin-left: 2px;
    }

    .above-limit {
      color: #008000 !important;
    }

    .above-limit span {
      color: #008000 !important;
    }

    .under-limit {
      color: #ff484e !important;
      font-weight: bold;
    }

    .under-limit span {
      color: #ff484e !important;
    }

    .company-summary-profile {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .company-key-statistics {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 100px;
    }

    .info-site {
        cursor: pointer;
        color: $default-primary-title-text-color;
    }

    @media screen and (max-width: 1024px) {

        .table-tag {
            width: 80%;
        }

        .info-title {
            font-size: 12px;
        }

        .info-value {
            font-size: 10px;
        }

        .line {
            height: 15%;
        }

        .type-percentage {
            font-size: 6px !important;
            font-style: italic !important;
            margin-bottom: 6px;
        }

        .statistics {
            overflow: auto;
        }

        .company-infos {
            overflow: auto;
            font-size: 10px;
        }

        .company-key-statistics {
            margin-left: 10px;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {
        
        .table-tag {
            width: 90%;
        }

        .info-title {
            font-size: 14px;
        }

        .info-value {
            font-size: 14px;
        }

        .line {
            height: 330px;
        }

        .type-percentage {
            font-size: 10px !important;
            font-style: italic !important;
        }

    }

</style>
  