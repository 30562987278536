<template>

    <div class="update-company-last-balance-dialog">

        <div v-if="company.type !== null && company.type !== undefined" class="body-info">
            <span class="amount"> <b> Quantidade </b> </span>
            <el-input-number v-if="company.type !== CompanyType.STOCK.value" v-model="company.amount" :min="1" :class="getCompanyAmountClass" size="small"/>
            <el-input-number v-if="company.type === CompanyType.STOCK.value" v-model="company.amount" :min="DEFAULT_STOCK_INITIAL_VALUE" :precision="DEFAULT_STOCK_DECIMAL" :step="DEFAULT_STOCK_INITIAL_VALUE"  :class="getCompanyAmountClass" size="small"/>
        </div>

        <div v-if="company.type !== null && company.type !== undefined" class="body-info">

            <span class="price"> <b> Valor </b></span>
            <currency-input v-model="company.price" :options="getCompanyCurrency(companyLastBalance)" class="price-input"/>

        </div>

        <div class="body-info">
            <span class="total"> <b> Total: </b> </span>
            <span> {{ getFormattedValue(company) }} </span>
        </div>

    </div>

</template>

<script>

    import { DEFAULT_STOCK_INITIAL_VALUE, DEFAULT_STOCK_DECIMAL } from '@/constants/AppConstants';
    import CompanyType from '@/constants/CompanyType';
    import CurrencyInput from "@/component/common/CurrencyInput";
    import { getFormattedValue } from '@/util/MoneyUtils';

    export default {
        name: 'update-company-last-balance-dialog',
        components: {
            CurrencyInput
        },
        data() {
            return {
                DEFAULT_STOCK_INITIAL_VALUE,
                DEFAULT_STOCK_DECIMAL,
                CompanyType
            }
        },
        props: {
            companyLastBalance: Object,
            currencyInputOptions: Function
        },
        methods: {
            getCompanyCurrency(company) {
                return this.currencyInputOptions(company);
            },
            getCompanyAmountClass() {
                return this.companyLastBalance.type !== this.CompanyType.STOCK.value ? "integer-amount-input" : "double-amount-input";
            },
            getFormattedValue(company) {

                if(company.type !== null) {
                    
                    const total = company.amount * company.price;
                    const locale = CompanyType[company.type].locale;

                    return getFormattedValue(total, locale);

                }

                return "-";

            }
        },
        computed: {
            company() {
                return this.companyLastBalance;
            }
        }
    }

</script>

<style lang="scss">

    .update-company-last-balance-dialog {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

    .body-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
    }

    .amount {
        margin-bottom: 5px;
        text-align: start;
    }

    .price {
        margin-bottom: 5px;
        text-align: start;
    }

    .total {
        text-align: start;
    }

    .price-input {
        width: 60% !important;
        text-align: center;
    }

    .el-input-number {
        width: 60% !important;
    }

    @media screen and (max-width: 1024px) {

        .amount {
            font-size: 8;
        }

        .price {
            font-size: 8;
        }

        .total {
            font-size: 8;
        }

        .price-input {
            padding: 6px 0px;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .amount {
            font-size: 12;
        }

        .price {
            font-size: 12;
        }

        .total {
            font-size: 12;
        }

        .price-input {
            padding: 8px 0px;
        }

    }

</style>
