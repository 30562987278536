<template>

  <div class="company-details-view">

    <div v-if="companyDetails.name != null" class="view-title">
      <div class="view-title-company">
        <img v-if="companyDetails.imageUrl != null" style="margin-right: 10px" :src="companyDetails.imageUrl" width="50" height="40">
        <h1 :class="getCompanyTypeClassName(companyDetails.type)"> {{ name }} </h1>
      </div>
      <span class="company-name"> {{ companyDetails.longName }} </span>
      <el-tooltip class="box-item" effect="dark" content="Recomendar ativo na comunidade" placement="top">
        <el-button v-if="isUserLogged() && !hasSuggestion()" type="success" @click="createCompanySuggestion()" class="dialog-confirm">
          <el-icon><UserFilled/></el-icon>
          <span v-if="!isPhoneScreenWidth"> Recomendar </span>
        </el-button>
      </el-tooltip>
      <el-button v-if="isUserLogged() && hasSuggestion()" type="danger" @click="deleteCompanySuggestion()" class="dialog-remove">
        <el-tooltip class="box-item" effect="dark" content="Remover recomendação de ativo na comunidade" placement="top">
          <span v-if="!isPhoneScreenWidth"> Remover recomendação </span>
          <el-icon v-if="isPhoneScreenWidth"><Close/></el-icon>
        </el-tooltip>
      </el-button>
    </div>

    <el-tabs v-model="activeName" @tab-click="handleChange">

      <el-tab-pane name="1">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverOne, 'is-active': activeName === '1' }"  @mouseover="isMouseOverOne = true" @mouseout="isMouseOverOne = false">
            Detalhes
          </span>
        </template>

        <section-row :isFirstSection="true">
          <company-details-section v-if="name != null" :name="name"/>
        </section-row>

      </el-tab-pane>

      <el-tab-pane name="2">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverTwo, 'is-active': activeName === '2' }" @mouseover="isMouseOverTwo = true" @mouseout="isMouseOverTwo = false">
            Proventos
          </span>
        </template>
        <company-dividends-section v-if="name !== null" :name="name"/>

      </el-tab-pane>

      <el-tab-pane v-if="isUserLogged()" name="3">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverThree, 'is-active': activeName === '3' }" @mouseover="isMouseOverThree = true" @mouseout="isMouseOverThree = false">
            Minhas transações
          </span>
        </template>
        <company-orders-section v-if="name !== null" :name="name"/>

      </el-tab-pane>

    </el-tabs>

    <div class="back-button">
      <el-tooltip class="box-item" effect="dark" content="Voltar para a última aba" placement="top">
        <button @click="goBack()" > Voltar </button>
      </el-tooltip>
    </div>

  </div>

</template>

<script>

  import SectionRow from '@/component/common/SectionRow';
  import CompanyDetailsSection from '@/section/company-details/tab/company-details/CompanyDetailsSection';
  import CompanyDividendsSection from '@/section/company-details/tab/company-dividend/CompanyDividendsSection';
  import CompanyOrdersSection from '@/section/company-details/tab/company-orders/CompanyOrdersSection';
  import CompanyType from '@/constants/CompanyType';
  import { ElMessage } from 'element-plus';
  import { isUserLogged } from '@/util/UserUtils';
  import { createCompanySuggestion, deleteCompanySuggestion } from '@/http/bff/company-bff-service';

  export default {
    name: 'company-details-view',
    components: { SectionRow, CompanyDetailsSection, CompanyDividendsSection, CompanyOrdersSection },
    data() {
        return {
          name: null,
          activeName: '1',
          isMouseOverOne: false,
          isMouseOverTwo: false,
          isMouseOverThree: false,
          lastActiveName: '1',
          companyDetails: {
            id: null,
            imageUrl: null,
            type: null,
            name: null,
            hasSuggestion: null,
            suggestionId: null
          }
        }
    },
    mounted() {
      document.title = this.name + " - Personal Invest";
    },
    created() {
      this.loadRouteParams();
    },
    methods: {
      createCompanySuggestion() {

        if(this.companyDetails.id !== null) {

          createCompanySuggestion(this.companyDetails.id).then(response => {
            ElMessage({
              message: 'Recomendação criada com sucesso!',
              type: 'success'
            });
            this.companyDetails.hasSuggestion = true;
            this.companyDetails.suggestionId = response.data.id;
          }).catch(() => {

            ElMessage({
              message: 'Recomendação não pode ser criada, tente novamente mais tarde!',
              type: 'error'
            });

          });

        }
        
      },
      deleteCompanySuggestion() {

        if(this.companyDetails.suggestionId !== null) {

          deleteCompanySuggestion(this.companyDetails.suggestionId).then(() => {
            ElMessage({
              message: 'Recomendação removida com sucesso!',
              type: 'success'
            });
            this.companyDetails.hasSuggestion = false;
          }).catch(() => {

            ElMessage({
              message: 'Recomendação não pode ser removida, tente novamente mais tarde!',
              type: 'error'
            });

          });
        
        }

      },
      isUserLogged() {
        return isUserLogged();
      },
      loadRouteParams() {
        this.name = this.$router.currentRoute.value.params.name.toUpperCase();
        this.emitter.on('setCompanyDetails', (companyDetails) => {
          this.companyDetails = companyDetails;
        });
      },
      handleChange() {
        this.lastActiveName = this.activeName;
        window.scroll(0, 0);
      },
      goBack() {

        const lastActiveName = this.activeName;

        this.activeName = this.lastActiveName;
        this.lastActiveName = lastActiveName;
        window.scroll(0, 0);

      },
      getCompanyTypeClassName(type) {
        return CompanyType[type].tagTypeClassName;
      },
      hasSuggestion() {
        return this.companyDetails.hasSuggestion === true;
      }
    },
    computed: {
      isPhoneScreenWidth() {
        return window.screen.width < 1024;
      }
    }
  }

</script>

<style lang="scss" scoped>

  .company-details-view {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .back-button {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .view-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .view-title-company {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .company-name {
    margin-top: 10px;
    color: $default-primary-title-text-color;
  }

  @media screen and (max-width: 1024px) {

    .dialog-confirm {
      width: 15%;
    }

    .dialog-remove {
      width: 15%;
    }

  }

  @media screen and (min-width: 1024px) and (max-width: 1920px) {

    .dialog-confirm {
      width: 8%;
    }

    .dialog-remove {
      width: 11.5%;
    }

  }

</style>
