<template>

  <div class="community-configuration-view">

    <h1> Configuração </h1>
    <section-row :isFirstSection="true">
      <community-configuration-option-section />
    </section-row>

  </div>

</template>

<script>

import SectionRow from '@/component/common/SectionRow';
import CommunityConfigurationOptionSection from '@/section/community/configuration/CommunityConfigurationSection';
import CompanyType from '@/constants/CompanyType';
import { ElMessage } from 'element-plus'
import { getCompaniesDividends } from '@/http/bff/earning-bff-service';

export default {
  name: 'community-configuration-view',
  components: { SectionRow, CommunityConfigurationOptionSection },
  data() {
    return {
      CompanyType,
      activeName: '1',
      lastActiveName: '1',
      isUserSummaryLoaded: false,
      viewerUserIdentifier: null,
      tableLoading: false
    }
  },
  methods: {
    handleChange() {
      this.lastActiveName = this.activeName;
      window.scroll(0, 0);
    },
    goBack() {

      const lastActiveName = this.activeName;

      this.activeName = this.lastActiveName;
      this.lastActiveName = lastActiveName;
      window.scroll(0, 0);

    },
    getCompaniesDividends(selectedCompaniesIds) {

      this.tableLoading = true;

      getCompaniesDividends(selectedCompaniesIds, this.viewerUserIdentifier).then(response => {

        this.companiesDividends = response.data;
        this.companiesDividends.companies.forEach(company => {

          company.typeClassName = CompanyType[company.type].className;
          company.tagTypeClassName = CompanyType[company.type].tagTypeClassName;

        });
        this.sortCompaniesByTypeAndNameAndPaymentDate();
        this.tableLoading = false;
        this.emitter.emit('refreshCharts');

      }).catch(() => {
        this.tableLoading = false;
        ElMessage({
          message: 'Erro ao buscar os dividendos, tente novamente mais tarde!',
          type: 'error'
        });
      });
    },
    sortCompaniesByTypeAndNameAndPaymentDate() {
      this.companiesDividends.companies.sort((c1, c2) => {

        let typeComparison = 0;
        if(c1.type < c2.type) {
          typeComparison = -1;
        }

        if(c1.type > c2.type) {
          typeComparison = 1;
        }

        let paymentDateComparison = 0;
        const c1PaymentDateTimestamp = Date.parse(c1.paymentDate);
        const c2PaymentDateTimestamp = Date.parse(c2.paymentDate);
        if(c1PaymentDateTimestamp < c2PaymentDateTimestamp) {
          paymentDateComparison = -1;
        }

        if(c1PaymentDateTimestamp > c2PaymentDateTimestamp) {
          paymentDateComparison = 1;
        }
        
        return typeComparison || paymentDateComparison;

      });
    },
    getCurrentMonthLabel() {

      const yearMonths = [
        {
          name: "Janeiro",
          index: 0
        },
        {
          name: "Fevereiro",
          index: 1
        },
        {
          name: "Março",
          index: 2
        },
        {
          name: "Abril",
          index: 3
        },
        {
          name: "Maio",
          index: 4
        },
        {
          name: "Junho",
          index: 5
        },
        {
          name: "Julho",
          index: 6
        },
        {
          name: "Agosto",
          index: 7
        },
        {
          name: "Setembro",
          index: 8
        },
        {
          name: "Outubro",
          index: 9
        },
        {
          name: "Novembro",
          index: 10
        },
        {
          name: "Dezembro",
          index: 11
        }
      ];
      const now = new Date();

      return {
        label: yearMonths[now.getMonth()].name + "/" + now.getFullYear(),
        name: yearMonths[now.getMonth()].name,
        index: now.getMonth(),
        year: now.getFullYear()
      };

    }
  },
  created() {
    this.emitter.on('viewerUser', (viewer) => {
      this.viewerUserIdentifier = viewer.userIdentifier;
      this.viewerUserInfo = viewer;

      if(this.companiesDividends.totalByType.length === 0) {
        const emptyArray = [];
        this.getCompaniesDividends(emptyArray);
      }

    });
    this.emitter.on('getCompaniesDividends', (selectedCompaniesIds) => {
      this.getCompaniesDividends(selectedCompaniesIds);
    });
  }
}
</script>

<style lang="scss" scoped>

  .community-configuration-view {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .back-button {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .user-info {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 2px;
    margin-top: 20px;
	}

  .user-info-detail {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 2px;
    margin-top: 20px;
  }

  .community-tabs {
    margin-top: 30px;
  }

</style>