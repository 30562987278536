<template>

  <div class="community-wallet-view">

    <h1> Carteira </h1>
    <section-row :isFirstSection="true">
      <community-user-option-section />
    </section-row>
    <el-tabs v-if="viewerUserIdentifier !== null && viewerUserIdentifier !== undefined" v-model="activeName" @tab-click="handleChange" class="community-tabs">

      <div class="user-info">
        <h3> Usuário </h3>
        <div class="user-info-detail">
          <img :src="viewerUserInfo.imageUrl" width="70" height="70" class="img">
          <span style="margin-left: 10px;"> @{{ viewerUserInfo.username }} </span>
        </div>
      </div>

      <el-tab-pane v-if="!isLoggedUserSummary" name="1">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverOne, 'is-active': activeName === '1' }"
            @mouseover="isMouseOverOne = true" @mouseout="isMouseOverOne = false">
            Carteira
          </span>
        </template>
        <section-row :isFirstSection="true">
          <dashboard-summary-section :userIdentifier="viewerUserIdentifier"/>
        </section-row>

      </el-tab-pane>

      <el-tab-pane v-if="!isLoggedUserSummary" name="2">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverTwo, 'is-active': activeName === '2' }"
            @mouseover="isMouseOverTwo = true" @mouseout="isMouseOverTwo = false">
            Proventos
          </span>
        </template>
        <section-row :isFirstSection="true">
          <dividend-summary-section v-if="companiesDividends !== undefined && companiesDividends.totalByType.length > 0" :companiesDividends="companiesDividends"
                                    :tableLoading="tableLoading"/>
        </section-row>

      </el-tab-pane>

      <el-tab-pane v-if="!isLoggedUserSummary" name="3">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverThree, 'is-active': activeName === '3' }"
            @mouseover="isMouseOverThree = true" @mouseout="isMouseOverThree = false">
            Magic Number
          </span>
        </template>
        <section-row :isFirstSection="true">
          <magic-number-section :companiesDividends="companiesDividends"
                                    :tableLoading="tableLoading"/>
        </section-row>

      </el-tab-pane>

      <el-tab-pane v-if="!isLoggedUserSummary" name="4">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverFour, 'is-active': activeName === '4' }"
            @mouseover="isMouseOverFour = true" @mouseout="isMouseOverFour = false">
            Ordens
          </span>
        </template>
        <section-row :isFirstSection="true">
          <order-section v-if="viewerUserIdentifier !== null && viewerUserIdentifier !== undefined" :userIdentifier="viewerUserIdentifier"/>
        </section-row>

      </el-tab-pane>

    </el-tabs>

    <div class="back-button">
      <el-tooltip class="box-item" effect="dark" content="Voltar para a última aba" placement="top">
        <button @click="goBack()"> Voltar </button>
      </el-tooltip>
    </div>

  </div>

</template>

<script>

import SectionRow from '@/component/common/SectionRow';
import CommunityUserOptionSection from '@/section/community/wallet/CommunityUserOptionSection';
import DashboardSummarySection from '@/section/community/wallet/tab/dashboard/DashboardSummarySection';
import DividendSummarySection from '@/section/community/wallet/tab/dividend/DividendSummarySection';
import MagicNumberSection from '@/section/community/wallet/tab/magic-number/MagicNumberSection';
import OrderSection from '@/section/community/wallet/tab/transaction/OrderSection';
import CompanyType from '@/constants/CompanyType';
import { ElMessage } from 'element-plus'
import { getCompaniesDividends } from '@/http/bff/earning-bff-service';

export default {
  name: 'community-wallet-view',
  components: { SectionRow, CommunityUserOptionSection, DashboardSummarySection, DividendSummarySection, MagicNumberSection, OrderSection },
  data() {
    return {
      CompanyType,
      activeName: '1',
      isMouseOverOne: false,
      isMouseOverTwo: false,
      isMouseOverThree: false,
      isMouseOverFour: false,
      lastActiveName: '1',
      isUserSummaryLoaded: false,
      viewerUserIdentifier: null,
      companiesDividends: {
        companies: [],
        currentCompaniesMagicNumber: [],
        total: 0,
        totalByType: []
      },
      tableLoading: false
    }
  },
  methods: {
    handleChange() {
      this.lastActiveName = this.activeName;
      window.scroll(0, 0);
    },
    goBack() {

      const lastActiveName = this.activeName;

      this.activeName = this.lastActiveName;
      this.lastActiveName = lastActiveName;
      window.scroll(0, 0);

    },
    getCompaniesDividends(selectedCompaniesIds) {

      this.tableLoading = true;

      getCompaniesDividends(selectedCompaniesIds, this.viewerUserIdentifier).then(response => {

        this.companiesDividends = response.data;
        this.companiesDividends.companies.forEach(company => {

          company.typeClassName = CompanyType[company.type].className;
          company.tagTypeClassName = CompanyType[company.type].tagTypeClassName;

        });
        this.sortCompaniesByTypeAndNameAndPaymentDate();
        this.tableLoading = false;
        this.emitter.emit('refreshCharts');

      }).catch(() => {
        this.tableLoading = false;
        ElMessage({
          message: 'Erro ao buscar os dividendos, tente novamente mais tarde!',
          type: 'error'
        });
      });
    },
    sortCompaniesByTypeAndNameAndPaymentDate() {
      this.companiesDividends.companies.sort((c1, c2) => {

        let typeComparison = 0;
        if(c1.type < c2.type) {
          typeComparison = -1;
        }

        if(c1.type > c2.type) {
          typeComparison = 1;
        }

        let paymentDateComparison = 0;
        const c1PaymentDateTimestamp = Date.parse(c1.paymentDate);
        const c2PaymentDateTimestamp = Date.parse(c2.paymentDate);
        if(c1PaymentDateTimestamp < c2PaymentDateTimestamp) {
          paymentDateComparison = -1;
        }

        if(c1PaymentDateTimestamp > c2PaymentDateTimestamp) {
          paymentDateComparison = 1;
        }
        
        return typeComparison || paymentDateComparison;

      });
    },
    getCurrentMonthLabel() {

      const yearMonths = [
        {
          name: "Janeiro",
          index: 0
        },
        {
          name: "Fevereiro",
          index: 1
        },
        {
          name: "Março",
          index: 2
        },
        {
          name: "Abril",
          index: 3
        },
        {
          name: "Maio",
          index: 4
        },
        {
          name: "Junho",
          index: 5
        },
        {
          name: "Julho",
          index: 6
        },
        {
          name: "Agosto",
          index: 7
        },
        {
          name: "Setembro",
          index: 8
        },
        {
          name: "Outubro",
          index: 9
        },
        {
          name: "Novembro",
          index: 10
        },
        {
          name: "Dezembro",
          index: 11
        }
      ];
      const now = new Date();

      return {
        label: yearMonths[now.getMonth()].name + "/" + now.getFullYear(),
        name: yearMonths[now.getMonth()].name,
        index: now.getMonth(),
        year: now.getFullYear()
      };

    }
  },
  created() {
    this.emitter.on('viewerUser', (viewer) => {
      this.viewerUserIdentifier = viewer.userIdentifier;
      this.viewerUserInfo = viewer;

      if(this.companiesDividends.totalByType.length === 0) {
        const emptyArray = [];
        this.getCompaniesDividends(emptyArray);
      }

    });
    this.emitter.on('getCompaniesDividends', (selectedCompaniesIds) => {
      this.getCompaniesDividends(selectedCompaniesIds);
    });
  }
}
</script>

<style lang="scss" scoped>

  .community-wallet-view {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .back-button {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .user-info {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 2px;
    margin-top: 20px;
	}

  .user-info-detail {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 2px;
    margin-top: 20px;
  }

  .community-tabs {
    margin-top: 30px;
  }

</style>