<template>

    <div class="community-home-section">

        <el-tabs v-model="activeName" @tab-click="handleChange">

            <el-tab-pane name="1">

                <template #label>
                    <span :class="{ 'is-mouse-over': isMouseOverOne, 'is-active': activeName === '1' }"  @mouseover="isMouseOverOne = true" @mouseout="isMouseOverOne = false">
                        Ordens
                    </span>
                </template>

                <div class="orders-sections">

                    <div class="transaction-container">
                        <section-row :isFirstSection="true">
                            <transaction-section/>
                        </section-row>
                    </div>

                    <div class="pending-order-container">
                        <section-row>
                            <pending-order-section/>
                        </section-row>
                    </div>

                </div>

            </el-tab-pane>

            <el-tab-pane name="2">

                <template #label>
                    <span :class="{ 'is-mouse-over': isMouseOverTwo, 'is-active': activeName === '2' }"  @mouseover="isMouseOverTwo = true" @mouseout="isMouseOverTwo = false">
                        Recomendações
                    </span>
                </template>

                <div class="suggestion-container">
                    <section-row :isFirstSection="true">
                        <suggestion-section/>
                    </section-row>
                </div>

            </el-tab-pane>

        </el-tabs>

    </div>

</template>

<script>

    import SuggestionSection from './items/tab/suggestion/SuggestionSection.vue';
    import TransactionSection from './items/tab/orders/TransactionSection.vue';
    import PendingOrderSection from './items/tab/orders/PendingOrderSection.vue';
    
    export default {
        name: 'community-home-section',
        components: { SuggestionSection, TransactionSection, PendingOrderSection },
        props: {
        },
        data() {
            return {
                activeName: '1',
                isMouseOverOne: false,
                isMouseOverTwo: false,
                lastActiveName: '1',
            }
        },
        created() {
        },
        methods: {
            handleChange() {
                this.lastActiveName = this.activeName;
                window.scroll(0, 0);
            }
        },
        computed: {
        }
    }

</script>

<style lang="scss" scoped>

    .community-home-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .el-tabs {
        width: 100%;
    }

    @media screen and (max-width: 1024px) {

        .orders-sections {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start
        }

        .transaction-container {
            width: 100%;
            max-width: 100%;
        }

        .pending-order-container {
            width: 100%;
            max-width: 100%;
        }

        .suggestion-container {
            width: 100%;
            max-width: 100%;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .orders-sections {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
        }

        .transaction-container {
            width: 50%;
            max-width: 50%;
        }

        .pending-order-container {
            width: 50%;
            max-width: 50%;
        }

        .suggestion-container {
            width: 100%;
            max-width: 100%;
        }

    }

</style>
