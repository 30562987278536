<template>

    <div class="suggestion-section">
        
        <h3>
            ATIVOS
            <el-tooltip class="box-item" effect="dark" content="Últimas recomendações de ativos criadas pela comunidade" placement="top">
                <el-icon class="info-icon"><InfoFilled/></el-icon>
            </el-tooltip>
        </h3>
        <div class="suggestion-section-table">

            <table v-loading="tableLoading" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">

                <thead>
                    <tr>
                        <th> Usuário </th>
                        <th> Ativo </th>
                        <th> Categoria </th>
                        <th> Data da criação </th>
                    </tr>
                </thead>
                <tbody v-if="suggestionsItems.length > 0">

                    <tr v-for="suggestion in suggestionsItems" :key="suggestion">

                        <td>
                            <div class="image-name">
                                <el-avatar v-if="suggestion.photoUrl !== null" :size="logoWidth" :src="suggestion.photoUrl" class="avatar" style="margin-right: 10px"/>
                                <span style="cursor: initial;"> @{{ suggestion.username }} </span>
                            </div>
                        </td>

                        <td @click="goToCompanyDetails(suggestion.company)">
                            <div class="image-name">
                                <img v-if="suggestion.company.imageUrl != null" style="margin-right: 10px" :src="suggestion.company.imageUrl" width="30" height="20">
                                <span> {{ suggestion.company.name }} </span>
                            </div>
                        </td>

                        <td>
                            <span :class="suggestion.typeClassName"> {{ suggestion.company.type }} </span>
                        </td>

                        <td>
                            <span> {{ getDateTime(suggestion.createdAt) }} </span>
                        </td>

                    </tr>

                </tbody>

            </table>

            <div v-if="suggestionsItems.length === 0 && !tableLoading">
                <EmptyResult></EmptyResult>
            </div>

        </div>

        <div v-if="suggestionsItems.length > 0" class="pagination-footer">

            <div class="pagination">
                <el-select v-model="pagination.limit" @change="getSuggestions()" class="pagination-select">

                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>

                </el-select>
                <el-pagination layout="prev, pager, next" :total="pagination.total" :page-size="pagination.limit" :current-page="page" @current-change="setPage"/>
            </div>

            <div class="number-register">
                {{ getNumberOfRegisterText() }}
            </div>

        </div>

    </div>

</template>

<script>

    import EmptyResult from '@/component/common/EmptyResult';
    import CompanyType from '@/constants/CompanyType';
    import { BLANK_PAGE_ARG } from '@/constants/AppConstants';
    import { COMPANY_DETAILS_PATH_WITHOUT_PARAM } from '@/constants/RoutesConstants';
    import { getCompaniesSuggestionsPaged } from '@/http/bff/company-bff-service';
    
    export default {
        name: 'suggestion-section',
        components: { EmptyResult },
        props: {
        },
        data() {
            return {
                suggestions: [],
                tableLoading: false,
                pagination: {
                    offset: 0,
                    limit: 5,
                    total: 0
                },
                dollarValue: null
            }
        },
        created() {
            this.getSuggestions();
        },
        methods: {
            getSuggestions() {
                this.tableLoading = true;
                getCompaniesSuggestionsPaged(this.pagination.offset, this.pagination.limit).then(response => {
                    this.suggestions = response.data.suggestions;
                    this.pagination = response.data.pagination;
                    this.dollarValue = response.data.dollarValue;
                    this.tableLoading = false;
                }).catch(() => this.tableLoading = false);
                
            },
            goToCompanyDetails(company) {
                const formattedUrl = COMPANY_DETAILS_PATH_WITHOUT_PARAM + "/" + company.name.toLowerCase();
                window.open(formattedUrl, BLANK_PAGE_ARG).focus();
            },
            getDateTime(dateTime) {

                if(dateTime !== undefined && dateTime !== null) {
                    var date = new Date(dateTime);
                    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
                }

                return null;

            },
            getNumberOfRegisterText() {

                const offset = this.pagination.offset + 1;
                const limit = this.pagination.limit + this.pagination.offset <= this.pagination.total ? this.pagination.limit + this.pagination.offset : this.pagination.total;

                return offset + " - " + limit + " de " + this.pagination.total + " recomendações";

            },
            setPage(page) {
                this.page = page;
                this.pagination.offset = (this.page - 1) * this.pagination.limit;
                this.getTransactions();
            }
        },
        computed: {
            suggestionsItems() {
                this.suggestions.forEach(suggestion => {
                    suggestion.typeClassName = CompanyType[suggestion.company.type].className;
                });
                return this.suggestions;
            }
        }
    }

</script>

<style lang="scss" scoped>

    .suggestion-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 20px;
    }

    .suggestion-section-table {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: auto;
        max-width: 100% !important;
        width: 100%;
    }

    .pagination-footer {
        width: 100%;
    }

    .number-register {
        display: flex;
        flex-direction: row;
        margin: 1px;
        margin-top: 15px;
        text-align: center;
        float: right;
        color: gray;
    }

    .image-name {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    @media screen and (max-width: 1024px) {

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {    

    }

</style>
