<template>
    
    <el-menu :default-active="currentPage" :collapse="isCollapsed" class="el-menu-vertical-demo">

        <div class="collapse-icons">
            <el-icon v-if="isCollapsed" size="30px" @click="openCollapse()" class="collapse-icon"><Expand/></el-icon>
            <el-icon v-if="!isCollapsed" size="30px" @click="closeCollapse()" class="collapse-icon"><Fold/></el-icon>
        </div>

        <el-menu-item index="1" @click="goTo(communityHomePath)" @click.middle="goToWithMiddleClick(communityHomePath)" class="menu-item">
            <el-icon><HomeFilled/></el-icon>
            <template #title > <span class="menu-item-text"> Página inicial </span> </template>
        </el-menu-item>

        <el-menu-item index="2" @click="goTo(communityWalletPath)" @click.middle="goToWithMiddleClick(communityWalletPath)" class="menu-item">
            <el-icon><Wallet/></el-icon>
            <template #title > <span class="menu-item-text"> Carteira </span> </template>
        </el-menu-item>

        <el-menu-item index="3" @click="goTo(communityConfigurationPath)" @click.middle="goToWithMiddleClick(communityConfigurationPath)" class="menu-item">
            <el-icon><Setting/></el-icon>
            <template #title> <span class="menu-item-text"> Configuração </span> </template>
        </el-menu-item>

    </el-menu>

</template>

<script>

import { COMMUNITY_HOME_PATH, COMMUNITY_WALLET_PATH, COMMUNITY_CONFIGURATION_PATH } from '../constants/RoutesConstants';
import { BLANK_PAGE_ARG } from '../constants/AppConstants';
import { HomeFilled } from '@element-plus/icons-vue';

export default {
    name: 'community-menu-view',
    components: [ HomeFilled ],
    props: {
        setMenuCollapsed: Function
    },
    data() {
        return {
            communityHomePath: COMMUNITY_HOME_PATH,
            communityWalletPath: COMMUNITY_WALLET_PATH,
            communityConfigurationPath: COMMUNITY_CONFIGURATION_PATH,
            pathsIndexs: {},
            isCollapsed: false
        }
    },
    created() {
        this.initPathIndexes();
    },
    methods: {
        initPathIndexes() {

            this.pathsIndexs[this.communityHomePath] = "1";
            this.pathsIndexs[this.communityWalletPath] = "2";
            this.pathsIndexs[this.communityConfigurationPath] = "3";

        },
        goTo(path) {
            this.$router.push({ path });
        },
        goToWithMiddleClick(path) {
            window.open(path, BLANK_PAGE_ARG).focus();
        },
        openCollapse() {
            this.isCollapsed = false;
            this.setMenuCollapsed(false);
        },
        closeCollapse() {
            this.isCollapsed = true;
            this.setMenuCollapsed(true);
        }
    },
    computed: {
        currentPage() {
            return this.pathsIndexs[this.$route.path];
        }
    }
}

</script>

<style lang="scss" scoped>

    @media screen and (max-width: 1024px) {

        .menu-item-text {
            display: none;
        }

        .el-menu--vertical:not(.el-menu--collapse):not(.el-menu--popup-container) .el-menu-item {
            padding: 12px;
            padding-left: 15px;
            height: 30px;
            margin-top: 10px;
            margin-left: 0px;
        }

        a {
            color: white;
            text-decoration: none;
        }

        .collapse-icon:hover {
            display: none;
        }
        
        .el-menu-item.is-active {
            background-color: $default-secondary-color;
            border: 1px solid $default-primary-title-text-color;
            border-radius: 5px;
            padding: 0px;
        }

        .el-menu-vertical-demo {
            background-color: $default-secondary-color;
            border-bottom: 1px solid white;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            padding-right: 2px;
            padding-left: 2px;
            width: 9.5%;
            font-weight: bold;
            margin-right: 50px;
            margin-top: 50px;
            position: fixed;
            float: left;
            overflow: hidden;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .collapse-icons {
            display: none;
        }
        
    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {
        .el-menu--vertical:not(.el-menu--collapse):not(.el-menu--popup-container) .el-menu-item {
            padding-left: 10px;
        }

        a {
            color: white;
            text-decoration: none;
            margin-left: 30px;
        }

        .collapse-icons {
            margin-bottom: 15px;
            color: $default-primary-title-text-color;
        }

        .el-menu-vertical-demo {
            background-color: $default-secondary-color;
            border-bottom: 1px solid white;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            padding-top: 30px;
            padding-bottom: 10px;
            padding-right: 5px;
            padding-left: 5px;
            width: 9.5%;
            font-weight: bold;
            margin-right: 50px;
            margin-top: 50px;
            position: fixed;
            float: left;
            overflow: hidden;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

    }

    
  
    .img {
        border: 1px solid white;
        border-radius: 50px;
        cursor: pointer;
        margin-bottom: 20px;
    }

    .menu-item {
        color: $default-primary-title-text-color;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        justify-content: center !important;
    }

    .el-menu--collapse {
        padding-top: 30px;
        padding-bottom: 10px;
        padding-right: 5px;
        padding-left: 5px;
        width: 5.1%;
        font-weight: bold;
        color: $default-primary-title-text-color;
    }

    .el-menu-item.is-active {
        background-color: $default-secondary-color;
        border: 1px solid $default-primary-title-text-color;
        border-radius: 5px;
    }

    .el-menu-item.menu-item:hover {
        color: white;
        background-color: $default-tag-text-color;
        border: 1px solid white;
        border-radius: 5px;
    }

    .el-menu-item.menu-item {
        margin-bottom: 20px;
    }

    .collapse-icon:hover {
        color: white;
    }

</style>
