// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyCblNqm6jvDDfseQjp0wHobLNzdbXQyoW0",
    authDomain: "personal-invest-firebase.firebaseapp.com",
    projectId: "personal-invest-firebase",
    storageBucket: "personal-invest-firebase.appspot.com",
    messagingSenderId: "729383802042",
    appId: "1:729383802042:web:ce5948020ea6cc9ae35e83",
    measurementId: "G-9YHWWRMRCH"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export { messaging };
