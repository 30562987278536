<template>

    <div class="community-view">
        <router-view></router-view>
    </div>

</template>
  
<script>
    export default {
        name: 'community-view',
        mounted() {
            document.title = "Comunidade - Personal Invest";
        }
    }
</script>
  
<style lang="scss" scoped>

    .community-view {
        width: inherit;
        height: inherit;
    }

</style>
