<template>

    <div class="company-search">
        <h3 v-if="title !== undefined && title !== null">
            ATIVOS
            <el-tooltip class="box-item" effect="dark" content="Busca de ativos" placement="top">
                <el-icon class="info-icon"><InfoFilled/></el-icon>
            </el-tooltip>
        </h3>
        <el-select style="height: 50px;" class="company-search-select" v-model="companyNameSearch" multiple remote reserve-keyword placeholder="Busque por ações, FII`s, BDR`s, Stocks, etc" :remote-method="getCompaniesWithText" :loading="loadingCompanies" filterable>
            <el-option v-for="company in companies" :key="company.name" :value="company.name" @click="goToCompanyDetails(company.name)">
                <div class="company-option">
                    <img :src="company.imageUrl" class="company-image-option">
                    <span> {{ company.name }} </span>
                </div>
            </el-option>
        </el-select>
    </div>

</template>

<script>

    import { getCompaniesByNameLike } from '@/http/bff/company-bff-service';
    import { COMPANY_DETAILS_PATH_WITHOUT_PARAM } from '@/constants/RoutesConstants';
    import { BLANK_PAGE_ARG } from '@/constants/AppConstants';

    export default {
        name: 'company-search',
        props: {
            title: Object
        },
        data() {
            return {
                companies: [],
                loadingCompanies: false
            }
        },
        methods: {
            getCompaniesWithText(companyName) {
                if(companyName !== null && companyName !==  undefined && companyName.length < 2) {
                    this.companies = [];
                } else if(companyName !== null && companyName !== undefined && companyName.length >= 2 && companyName.length <= 6) {
                    this.loadingCompanies = true;
                    getCompaniesByNameLike(companyName).then(response => {
                        this.companies = response.data.companies;
                        this.loadingCompanies = false;
                    }).catch(() => this.loadingCompanies = false);
                }
            },
            goToCompanyDetails(name) {
                if(name !== null) {
                    const formattedUrl = COMPANY_DETAILS_PATH_WITHOUT_PARAM + "/" + name.toLowerCase();
                    window.open(formattedUrl, BLANK_PAGE_ARG).focus();
                }
            }
        }
    }

</script>

<style lang="scss" scoped>

    .company-search {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 50px;
    }

    .search-icon {
        color: gray !important;
        cursor: default;
    }

    .company-option {
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 5px;
    }

    .company-image-option {
        margin-top: 0px !important;
        width: 30px !important;
        height: 30px !important;
        margin-right: 5px;
    }

    @media screen and (max-width: 1024px) {

        .company-search-select {
            width: 60%;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .company-search-select {
            width: 300px;
        }

    }

</style>
