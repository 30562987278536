<template>

	<div class="community-user-option">

		<div class="viewers-config-table">

			<table v-loading="tableLoading" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">

				<thead>
					<tr>
						<th> Usuário </th>
						<th> Ações </th>
					</tr>
				</thead>
				<tbody v-if="walletViewing.length > 0">

					<tr v-for="viewing in walletViewing" :key="viewing.id">

						<td>

							<div class="viewer-profile">

							<el-avatar size="40" :src="viewing.viewer.imageUrl" class="avatar"/>
							<span style="margin-left: 15px;"> @{{ viewing.viewer.username }} </span>

							</div>
							
						</td>

						<td>
						
							<div class="actions">
								<el-tooltip v-if="viewing.status === PrivacyViewerConfigStatus.ACTIVE.value" class="box-item" effect="dark" content="Visualizar carteira" placement="top">
									<el-button type="success" icon="View" circle :disabled="userView !== null && userView.userIdentifier === viewing.viewer.userIdentifier" @click="getSummary(viewing.viewer)" class="action-button"/>
								</el-tooltip>
							</div>

						</td>

					</tr>

				</tbody>

			</table>
			<div v-if="walletViewing.length === 0">
				<EmptyResult></EmptyResult>
			</div>

		</div>

	</div>

</template>

<script>

import { getUserPrivacyConfig } from '@/http/bff/user-bff-service';
import { DEFAULT_LOADING_SVG } from '@/constants/AppConstants';
import PrivacyViewerConfigStatus from '@/constants/PrivacyViewerConfigStatus';
import EmptyResult from '@/component/common/EmptyResult';

export default {
	name: 'community-user-option-section',
	components: { EmptyResult },
	data() {
		return {
			PrivacyViewerConfigStatus,
			privacyConfig: {
				configurations: [],
				viewers: [],
				viewing: []
			},
			tableLoading: false,
			loadingSvg: DEFAULT_LOADING_SVG,
			userView: null
		}
	},
	created() {
		this.getUserPrivacyConfig();
	},
	methods: {
		getUserPrivacyConfig() {

			this.tableLoading = true;
			getUserPrivacyConfig().then(response => {
				this.privacyConfig = response.data;
				this.tableLoading = false;
			}).catch(() => this.tableLoading = false);

		},
		getSummary(viewer) {
			this.emitter.emit('viewerUser', viewer);
			this.userView = viewer;
		}
	},
	computed: {
		walletViewing() {
			return this.privacyConfig.viewing.filter(viewing => viewing.status === PrivacyViewerConfigStatus.ACTIVE.value);
		}
	}
}
</script>

<style lang="scss" scoped>

	.community-user-option {
		text-align: center;
		display: flex;
		flex-direction: column;
	}

	.viewer-profile {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.create-viewer-profile {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-top: 10px;
	}

	.user-option {
		height: 30px;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 2px;
	}

	.user-search-config-table {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px;
	}

	.company-search-input {
		margin-top: 2px;
	}

	.table-buttons {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-top: 10px;
	}

	@media screen and (min-width: 1024px) and (max-width: 1920px) {

		.company-search-input {
            width: 130px;
            height: 30px;
            margin-bottom: 2px;
        }

	}

</style>