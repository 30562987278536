import { httpConfiguration } from '../base-service';

const http = httpConfiguration('https://api.personalinvest.co/api/company-bff-service/v1');

export const getCompaniesPaged = (offset = 0, limit = 10, sorts = [], filter = {}) => {

  let params =  { offset, limit, sorts: [], status: [], type: [] };

  if(sorts.length > 0) {
    params["sorts"] = sorts.toString();
  }

  if(filter.selectedStatus.length > 0) {
    params["status"] = filter.selectedStatus.toString();
  }

  if(filter.selectedTypes.length > 0) {
    params["types"] = filter.selectedTypes.toString();
  }

  if(filter.searchText !== null) {
    params["searchText"] = filter.searchText;
  }

  if(filter.startDateAt !== null) {
    params["startDateAt"] = filter.startDateAt;
  }

  if(filter.endDateAt !== null) {
    params["endDateAt"] = filter.endDateAt;
  }

  if(filter.avoidUserCompanies !== null) {
    params["avoidUserCompanies"] = filter.avoidUserCompanies;
  }

  return http.get('/company', { params });

};

export const createCompanySuggestion = (companyId) => {
  const body = {
    "companyId": companyId
  };
  return http.post('/community/suggestion', body);
};

export const getCompaniesSuggestionsPaged = (offset = 0, limit = 10) => {
  let params =  { offset, limit };
  return http.get('/community/suggestion', { params });
};

export const deleteCompanySuggestion = (id) => {
  return http.delete('/community/' + id + '/suggestion/');
};

export const updateCompanyLastBalance = (id, balance) => http.put('/company/' + id + '/balance', balance);

export const getCompanyDetails = (name, dateInterval) => {
  let params =  { name, dateInterval };
  return http.get('/company/details', { params });
};

export const getCompanyOrders = (name, offset = 0, limit = 10, sorts = []) => {

  let params =  { offset, limit, sorts: [] };

  if(sorts.length > 0) {
    params["sorts"] = sorts.toString();
  }

  return http.get('/company/' + name + '/orders', { params });

};

export const getCompaniesByNameLike = (name) => {
  return http.get('/company/' + name);
};

export const createCompanyAlert = (alert) => {
  return http.post('/alert', alert);
};

export const getCompaniesAlerts = () => {
  return http.get('/alert');
};

export const updateCompanyAlert = (id, alert) => {
  return http.put('/alert/' + id, alert);
};

export const deleteCompanyAlert = (id) => {
  return http.delete('/alert/' + id);
};
