<template>

  <div class="dashboard-view">

    <h1> Dashboard </h1>
    <el-tabs v-model="activeName" @tab-click="handleChange">

      <el-tab-pane name="1">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverOne, 'is-active': activeName === '1' }"
            @mouseover="isMouseOverOne = true" @mouseout="isMouseOverOne = false">
            Carteira
          </span>
        </template>
        <dashboard-summary-section />

      </el-tab-pane>

      <el-tab-pane name="2">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverTwo, 'is-active': activeName === '2' }"
            @mouseover="isMouseOverTwo = true" @mouseout="isMouseOverTwo = false">
            Rentabilidade
          </span>
        </template>
        <profit-summary-section />

      </el-tab-pane>

    </el-tabs>

    <div class="back-button">
      <el-tooltip class="box-item" effect="dark" content="Voltar para a última aba" placement="top">
        <button @click="goBack()"> Voltar </button>
      </el-tooltip>
    </div>

  </div>

</template>

<script>

import DashboardSummarySection from '@/section/dashboard/tab/summary/DashboardSummarySection';
import ProfitSummarySection from '@/section/dashboard/tab/profit/ProfitSummarySection';
import { DASHBOARD_PATH } from '@/constants/RoutesConstants';
import { getUserFromCache, addUserCache } from '@/util/UserUtils';
import { messaging } from '@/firebase';
import { getToken } from 'firebase/messaging';
import { setUserNotificationToken } from '@/http/bff/user-bff-service'

export default {
  name: 'dashboard-view',
  components: { DashboardSummarySection, ProfitSummarySection },
  data() {
    return {
      activeName: '1',
      isMouseOverOne: false,
      isMouseOverTwo: false,
      lastActiveName: '1'
    }
  },
  methods: {
    async requestNotificationPermission() {
      try {
        const swRegistration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');

        const token = await getToken(messaging,
          {
            vapidKey: 'BJRoSXSARUFUZoafLyz2wErI89jI65AkKjB8UgMwU0qi7iaI0qxQ-G_AGu9y-d_lCwECR490UuscYjTLFiJ2FAQ',
            serviceWorkerRegistration: swRegistration
          });

        if (token) {
          const user = getUserFromCache();
          if (user.notificationToken === null || user.notificationToken !== token) {
            await setUserNotificationToken({ token });
            user.notificationToken = token;
            addUserCache(user);
            console.log('Novo token obtido:', token);
          } else {
            console.log('Token ja registrado:', token);
          }
        } else {
          console.log('Nenhum token disponível. Solicitação de permissão pode ter sido rejeitada.');
        }
      } catch (error) {
        console.error('Erro ao obter o token:', error);
      }
    },
    handleChange() {
      this.lastActiveName = this.activeName;
      window.scroll(0, 0);
    },
    goBack() {

      const lastActiveName = this.activeName;

      this.activeName = this.lastActiveName;
      this.lastActiveName = lastActiveName;
      window.scroll(0, 0);

    }
  },
  mounted() {

    if (this.$router.currentRoute.path === DASHBOARD_PATH) {
      document.title = "Dashboard - Personal Invest";
    }
    this.requestNotificationPermission();

  }
}
</script>

<style lang="scss" scoped>
.dashboard-view {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.back-button {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>