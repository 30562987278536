<template>

    <div class="create-pending-order-dialog" v-loading="loading" element-loading-text="Carregando..." :element-loading-svg="loadingSvg" element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">

        <div v-if="showFields" class="pending-order-info">

            <div class="pending-order-info-type">

                <span class="pending-order-info-type-title"> Ordem: </span>
                <div class="pending-order-info-order">

                    <div class="pending-order-info-order-row">

                        <div class="pending-order-info-container">
                            <span class="title"> Operação </span>
                            <el-select v-model="newPlanningOrder.type" @change="newPlanningOrderTypeChange(newPlanningOrder)" class="m-2" placeholder="Selecione" size="small">
                                <el-option v-for="orderType in originalTypeList" :key="orderType" :label="getTypeText(orderType)" :value="orderType"/>
                            </el-select>
                        </div>

                    </div>

                    <div class="pending-order-info-order-row">

                        <div class="pending-order-info-container">
                            <span class="title"> Status: </span>
                            <el-select v-if="statusList.length > 0" v-model="pendingOrder.status" @change="statusChanged(newPlanningOrder)" class="m-2" placeholder="Selecione" size="small">
                                <el-option v-for="status in statusList" :key="status" :label="getStatusText(status)" :value="status"/>
                            </el-select>
                        </div>

                        <div v-if="pendingOrder.status ===  OrderStatus.EXECUTED.value" class="dates pending-order-info-container">
                            <span class="title"> Data de execução </span>
                            <div>
                                <el-date-picker v-model="pendingOrder.executedAt" type="datetime" format="DD/MM/YYYY HH:mm:ss" placeholder="Data de execução" size="medium"/>
                            </div>
                        </div>

                    </div>

                    <div class="pending-order-info-order-row">

                        <div class="pending-order-info-container">
                            <span class="title"> Categoria </span>
                            <el-select v-if="companyTypeList.length > 0" v-model="newPlanningOrder.company.type" @change="resetPlanningOrderAndGetCompanies()" class="m-2" placeholder="Selecione" size="small">
                                <el-option v-for="company in companyTypeList" :key="company" :label="getCompanyText(company)" :value="company"/>
                            </el-select>
                        </div>

                        <div class="pending-order-info-container">
                            <span class="title"> Ativo </span>
                            <el-select v-if="companyList.length > 0" v-model="newPlanningOrder.company.id" @change="setCurrentPrice()" filterable class="m-2" placeholder="Selecione" size="small">
                                <el-option v-for="company in companyList" :key="company.id" :label="company.name" :value="company.id">
                                    <img v-if="company.imageUrl !== undefined" style="margin-right: 10px; cursor: pointer;" :src="company.imageUrl" width="30" height="20">
                                    <span> {{ company.name }} </span>
                                </el-option>
                            </el-select>
                            <span v-if="companyList.length <= 0">-</span>
                        </div>

                    </div>

                    <div class="pending-order-info-order-row">

                        <div class="pending-order-info-container">
                            <span class="title"> Quantidade </span>
                            <div v-if="newPlanningOrder.company.type !== null && newPlanningOrder.company.type !== undefined">
                                <el-input-number v-if="newPlanningOrder.company.type !== CompanyType.STOCK.value" v-model="newPlanningOrder.amount" :min="1"  :class="getCompanyAmountClass"/>
                                <el-input-number v-if="newPlanningOrder.company.type === CompanyType.STOCK.value" v-model="newPlanningOrder.amount" :min="DEFAULT_STOCK_INITIAL_VALUE" :precision="DEFAULT_STOCK_DECIMAL" :step="DEFAULT_STOCK_INITIAL_VALUE" :class="getCompanyAmountClass" @change="orderChanged()"/>
                            </div>
                            <div v-if="newPlanningOrder.company.type === null || newPlanningOrder.company.type === undefined">
                            -
                            </div>
                        </div>

                        <div class="pending-order-info-container">
                            <span class="title"> Preço </span>
                            <currency-input v-if="newPlanningOrder.company.id !== null" v-model="newPlanningOrder.price" :options="getCompanyCurrency(newPlanningOrder.company.type)" @change="orderChanged()" class="price-input"/>
                            <div v-if="newPlanningOrder.company.id === null || newPlanningOrder.company.id === undefined || newPlanningOrder.price === null">
                            -
                            </div>
                        </div>

                    </div>

                </div>

            </div>

            <div class="pending-order-info-type">

                <span class="pending-order-info-type-title"> Total: </span>
                <div class="pending-order-info-row">

                    <div class="pending-order-info-container">
                        <span class="title"> Ordem </span>
                        <currency-input v-if="newPlanningOrder.company.id !== null && newPlanningOrder.total !== null && newPlanningOrder.company.type === CompanyType.STOCK.value" v-model="newPlanningOrder.total" :options="getCompanyCurrency(newPlanningOrder.company.type)" @change="orderTotalChanged()" class="price-input"/>
                        <el-tag v-if="newPlanningOrder.company.type !== CompanyType.STOCK.value" :key="newPlanningOrder.company.id" class="mx-1 table-tag" :disable-transitions="false">
                            <span class="el-tag__content"> {{ getNewOrderTotalValue(newPlanningOrder) }} </span>
                        </el-tag>
                    </div>

                    <div class="pending-order-info-container">
                        <span class="title"> Atual </span>
                        <el-tag :key="newPlanningOrder.company.id" class="mx-1 table-tag" :disable-transitions="false">
                            <span class="el-tag__content"> {{ getNewCompanyTotalValue() }} </span>
                        </el-tag>
                    </div>

                    <div class="pending-order-info-container">
                        <span class="title"> Final </span>
                        <el-tag :key="newPlanningOrder.company.id" class="mx-1 table-tag" :disable-transitions="false">
                            <span class="el-tag__content"> {{ getNewCompanyFinalValue(newPlanningOrder) }} </span>
                        </el-tag>
                    </div>

                </div>

            </div>

            <div class="pending-order-info-type">

                <span class="pending-order-info-type-title"> Quantidade: </span>
                <div class="pending-order-info-row">

                    <div class="pending-order-info-container">
                        <span class="title"> Ordem </span>
                        <el-tag :key="newPlanningOrder.company.id" class="mx-1 table-tag" :disable-transitions="false">
                            <span class="el-tag__content"> {{ getCompanyOrderAmount() }} </span>
                        </el-tag>
                    </div>

                    <div class="pending-order-info-container">
                        <span class="title"> Atual </span>
                        <el-tag :key="newPlanningOrder.company.id" class="mx-1 table-tag" :disable-transitions="false">
                            <span class="el-tag__content"> {{ getCompanyUserAmount() }} </span>
                        </el-tag>
                    </div>

                    <div class="pending-order-info-container">
                        <span class="title"> Final </span>
                        <el-tag :key="newPlanningOrder.company.id" class="mx-1 table-tag" :disable-transitions="false">
                            <span class="el-tag__content"> {{ getFinalCompanyUserAmount() }} </span>
                        </el-tag>
                    </div>

                </div>

            </div>
    
            <div class="pending-order-info-type">

                <span class="pending-order-info-type-title"> Preço médio: </span>
                <div class="pending-order-info-row">

                    <div class="pending-order-info-container">
                        <span class="title"> Atual </span>
                        <el-tag :key="newPlanningOrder.company.id" class="mx-1 table-tag" :disable-transitions="false">
                            <span class="el-tag__content"> {{ getUserCompanyPriceValue() }} </span>
                        </el-tag>
                    </div>

                    <div class="pending-order-info-container">
                        <span class="title"> Final </span>
                        <el-tag :key="newPlanningOrder.company.id" class="mx-1 table-tag" :disable-transitions="false">
                            <span class="el-tag__content"> {{ getFinalCompanyPriceValue() }} </span>
                        </el-tag>
                    </div>

                </div>

            </div>

            <div class="footer">
                <el-button type="success" class="dialog-confirm" @click="createPlanningOrder()" :disabled="!validPendingRequest(newPlanningOrder)">
                    <span v-if="!isPhoneScreenWidth"> Confirmar </span>
                    <el-tooltip v-if="isPhoneScreenWidth" class="box-item" effect="dark" content="Criar ordem" placement="top">
                        <el-icon><Check/></el-icon>
                    </el-tooltip>
                </el-button>
                <el-button type="danger" class="dialog-close" @click="closeDialog()">
                    <span v-if="!isPhoneScreenWidth"> Cancelar </span>
                    <el-tooltip v-if="isPhoneScreenWidth" class="box-item" effect="dark" content="Cancelar ordem" placement="top">
                        <el-icon><Close/></el-icon>
                    </el-tooltip>
                </el-button>
            </div>

        </div>

        <div v-if="!showFields" class="pending-order-info">

            <span class="title"> Continuar criando ordens? </span>
            <div class="continue">
                <el-button type="success" class="dialog-confirm" @click="showFields = true">
                    <span v-if="!isPhoneScreenWidth"> Continuar </span>
                    <el-tooltip v-if="isPhoneScreenWidth" class="box-item" effect="dark" content="Continuar criando ordens" placement="top">
                        <el-icon><Check/></el-icon>
                    </el-tooltip>
                </el-button>
                <el-button class="dialog-close" @click="closeDialog()">
                    <span v-if="!isPhoneScreenWidth" class="close-label"> Finalizar </span>
                    <el-tooltip v-if="isPhoneScreenWidth" class="box-item" effect="dark" content="Finalizar criação de ordens" placement="top">
                        <el-icon><Close/></el-icon>
                    </el-tooltip>
                </el-button>

            </div>

        </div>

    </div>

</template>

<script>

    import OrderStatus from '@/constants/OrderStatus';
    import OrderType from '@/constants/OrderType';
    import CompanyType from '@/constants/CompanyType';
    import CompanyStatus from '@/constants/CompanyStatus';
    import CurrencyInput from "@/component/common/CurrencyInput";
    import { ElMessage } from 'element-plus';
    import { getCompanies, getCompanyCurrentPrice, createPlanningOrder } from '@/http/bff/planning-bff-service';
    import { DEFAULT_STOCK_INITIAL_VALUE, DEFAULT_STOCK_DECIMAL, DEFAULT_LOADING_SVG } from '@/constants/AppConstants';
    import { getTotalValue, getFormattedValue } from '@/util/MoneyUtils';

    export default {
        name: 'create-pending-order-dialog',
        components: { CurrencyInput },
        data() {
            return {
                OrderStatus,
                CompanyType,
                CompanyStatus,
                newPlanningOrder: {
                    company: {
                        id: null,
                        name: null,
                        type: null,
                        balance: 0
                    },
                    amount: 1,
                    price: 0,
                    total: null,
                    type: OrderType.BUY.value,
                    status: OrderStatus.PENDING.value,
                    companyId: null
                },
                companies: [],
                showFields: true,
                loading: false,
                loadingSvg: DEFAULT_LOADING_SVG,
                DEFAULT_STOCK_INITIAL_VALUE,
                DEFAULT_STOCK_DECIMAL,
                DEFAULT_LOADING_SVG
            }
        },
        methods: {
            getStatusText(status) {
                return OrderStatus[status].description;
            },
            getCompanyText(type) {
                return CompanyType[type].description;
            },
            setCurrentPrice() {

                this.newPlanningOrder.price = null;
                const company = this.companies.find(company => company.id === this.newPlanningOrder.company.id);
                this.loading = true;
                getCompanyCurrentPrice(company.name, this.newPlanningOrder.company.id).then(response => {
                    this.newPlanningOrder.price = response.data.currentPrice;
                    company.amount = response.data.amount;
                    company.balance = response.data.balance;
                    company.price = response.data.price;
                    this.loading = false;
                }).catch(() => {
                    ElMessage({
                        message: "Erro ao buscar valor atual do ativo",
                        type: 'error'
                    });
                    this.loading = false;
                });

            },
            resetPlanningOrderAndGetCompanies() {
                this.newPlanningOrder.company.id = null;
                this.newPlanningOrder.company.name = null;
                this.newPlanningOrder.company.balance = 0;
                this.newPlanningOrder.amount = 1;
                this.newPlanningOrder.price = null;
                this.newPlanningOrder.total = null;
                this.newPlanningOrder.type = OrderType.BUY.value;
                this.newPlanningOrder.companyId  = null;
                this.companies = [];
                this.getCompanies();
            },
            getCompanies() {

                if(this.newPlanningOrder.company.type !== null) {
                    const filter = {
                        selectedTypes: [ this.newPlanningOrder.company.type ],
                        selectedStatus: [ CompanyStatus.ACTIVE.value ],
                        sorts: [
                            "type###asc",
                            "name###asc"
                        ]
                    };
                    this.loading = true;
                    getCompanies(filter).then(response => {
                        this.companies = response.data.companies;
                        this.loading = false;
                    }).catch(() => this.loading = false);
                }

            },
            statusChanged(order) {
                order.executedAt = null;
            },
            newPlanningOrderTypeChange(order) {

                if(order.type !== null && order.type !== undefined && order.company.id !== null && order.company.id !== undefined) {

                    if(order.type === OrderType.BUY.value) {

                        if(order.type === CompanyType.STOCK.value) {
                            order.amount = DEFAULT_STOCK_INITIAL_VALUE;
                        } else {
                            order.amount = 1;
                        }

                    } else {
                        const company = this.companies.find(company => company.id = order.company.id);
                        order.amount = company.amount;
                    }

                }

            },
            getCompanyCurrency(type) {

                if(type === CompanyType.STOCK.value) {
                    return { currency: "USD" };
                }

                return { currency: "BRL" };

            },
            orderChanged() {
                this.newPlanningOrder.total = this.newPlanningOrder.amount * this.newPlanningOrder.price;
            },
            orderTotalChanged() {
                this.newPlanningOrder.amount = this.newPlanningOrder.total/this.newPlanningOrder.price;
            },
            getNewOrderTotalValue(order) {

                if(this.newPlanningOrder.company.id !== null && this.companies.length > 0) {

                    const companyType = this.companies.find(company => company.id === this.newPlanningOrder.company.id).type;
                    const total = parseFloat(getTotalValue(order.amount, order.price));
                    const locale = CompanyType[companyType].locale;

                    return getFormattedValue(total, locale);

                }

                return "-";

            },
            getNewCompanyTotalValue() {

                if(this.newPlanningOrder.company.id !== null && this.companies.length > 0) {

                    const companyOrder = this.companies.find(company => company.id === this.newPlanningOrder.company.id);

                    if(companyOrder != null && companyOrder.type != null) {
                        const locale = CompanyType[companyOrder.type].locale;
                        return getFormattedValue(companyOrder.balance, locale);
                    }

                }

                return "-";

            },
            getNewCompanyFinalValue(order) {

                if(this.newPlanningOrder.company.id !== null && this.newPlanningOrder.company.type !== null && this.companies.length > 0) {

                    const companyOrder = this.companies.find(company => company.id === this.newPlanningOrder.company.id);
                    const orderTotalValue = parseFloat(getTotalValue(order.amount, order.price));

                    if(companyOrder != null && companyOrder.type != null && companyOrder.id !== null) {

                        let total = 0;
                        if(order.type === OrderType.BUY.value){
                        
                            const totalFinal = companyOrder.balance + orderTotalValue
                            total = parseFloat(totalFinal);

                        } else {

                            const totalFinal = companyOrder.balance - orderTotalValue
                            total = parseFloat(totalFinal);

                        }
                        const locale = CompanyType[companyOrder.type].locale;

                        return getFormattedValue(total, locale);

                    }

                }

                return "-";

            },
            getCompanyOrderAmount() {

                if(this.newPlanningOrder !== null && this.newPlanningOrder !== undefined && this.newPlanningOrder.amount !== null) {

                    if(this.newPlanningOrder.company.type === CompanyType.STOCK.value) {
                        return this.newPlanningOrder.amount.toFixed(DEFAULT_STOCK_DECIMAL);
                    }

                    return this.newPlanningOrder.amount;

                }

                return "-";

            },
            getCompanyUserAmount() {

                const company = this.companies.find(company => company.id === this.newPlanningOrder.company.id);
                if(company !== null && company !== undefined && company.type !== null) {

                    if(company.type === CompanyType.STOCK.value) {
                        return company.amount.toFixed(DEFAULT_STOCK_DECIMAL);
                    }

                    return company.amount;

                }

                return "-";

            },
            getFinalCompanyUserAmount() {

                const company = this.companies.find(company => company.id === this.newPlanningOrder.company.id);
                if(company !== null && company !== undefined && company.type !== null && this.newPlanningOrder.type !== null) {

                    const companyAmount = company.amount;
                    const orderAmount = this.newPlanningOrder.amount;

                    let total = 0;
                    if(this.newPlanningOrder.type == OrderType.BUY.value) {
                        total = orderAmount + companyAmount;
                    } else {
                        total = companyAmount - orderAmount;
                    }

                    if(this.newPlanningOrder.company.type === CompanyType.STOCK.value) {
                        return total.toFixed(DEFAULT_STOCK_DECIMAL);
                    }

                    return total;

                }

                return "-";

            },
            getUserCompanyPriceValue() {

                const company = this.companies.find(company => company.id === this.newPlanningOrder.company.id);
                if(company !== null && company !== undefined && company.type !== null) {
                    const locale = CompanyType[company.type].locale;
                    return getFormattedValue(company.price, locale);
                }

                return "-";

            },
            getFinalCompanyPriceValue() {

                const company = this.companies.find(company => company.id === this.newPlanningOrder.company.id);
                if(company !== null && company !== undefined && company.type !== null && this.newPlanningOrder.type !== null) {

                    const companyPrice = company.price;
                    const companyAmount = company.amount;
                    const orderPrice = this.newPlanningOrder.price;
                    const orderAmount = this.newPlanningOrder.amount;

                    let total = 0;
                    if(this.newPlanningOrder.type == OrderType.BUY.value) {
                        total = ((companyPrice * companyAmount) + (orderPrice * orderAmount))/(orderAmount + companyAmount);
                    } else {
                        total = companyAmount - orderAmount === 0 ? 0 : ((companyPrice * companyAmount) - (orderPrice * orderAmount))/(companyAmount - orderAmount);
                    }

                    const locale = CompanyType[company.type].locale;

                    return getFormattedValue(total, locale);

                }

                return "-";

            },
            getTypeText(type) {
                return OrderType[type].description;
            },
            createPlanningOrder() {

                if(this.validPendingRequest(this.newPlanningOrder)) {

                    this.newPlanningOrder.companyId = this.newPlanningOrder.company.id;
                    this.loading = true;
                    createPlanningOrder(this.newPlanningOrder).then(() => {

                        const status = OrderStatus[this.newPlanningOrder.status].description.toLowerCase();
                        const message = 'Ordem ' + status + ' criada com sucesso!';
                        ElMessage({
                            message: message,
                            type: 'success'
                        });

                        this.newPlanningOrder = {
                            company: {
                                id: null,
                                name: null,
                                type: null,
                                balance: 0
                            },
                            amount: 1,
                            price: 0,
                            total: null,
                            type: OrderType.BUY.value,
                            status: OrderStatus.PENDING.value,
                            companyId: null
                        };
                        this.loading = false;
                        this.showFields = false;

                    }).catch(() => {
                        const status = OrderStatus[this.newPlanningOrder.status].description.toLowerCase();
                        const message = 'Ordem ' + status + ' não pode ser criada, tente novamente mais tarde!';
                        ElMessage({
                            message: message,
                            type: 'error'
                        });
                        this.loading = false;
                    });
                }

            },
            closeDialog() {
                this.$emit('closePendingOrderDialog', {});
                this.emitter.emit('refreshSummary', {});
            },
            validPendingRequest(order) {

                if(order.company === null || order.company === undefined || order.company.id === null || order.company.id === "") {
                return false;
                }

                if(order.amount === null || order.amount === undefined || order.amount === "" || order.amount <= 0) {
                return false;
                }

                if(order.price === null || order.price === undefined || order.price === "" || order.price <= 0) {
                return false;
                }

                if(order.type === null || order.type === undefined || order.type === "") {
                return false;
                }

                if(order.status === null || order.status === undefined || order.status === "") {
                    return false;
                }

                if(OrderStatus.EXECUTED.value === order.status && (order.executedAt === null || order.executedAt === undefined)) {
                    return false;
                }

                return true;

            }
        },
        computed: {
            statusList() {
                let statusList = Object.keys(OrderStatus);
                return statusList.filter(status => status === OrderStatus.PENDING.value || status === OrderStatus.EXECUTED.value);
            },
            pendingOrder() {
                return this.newPlanningOrder;
            },
            companyTypeList() {
                return Object.keys(CompanyType);
            },
            companyList() {
                return this.companies;
            },
            isPhoneScreenWidth() {
                return window.screen.width < 1024;
            },
            originalTypeList() {
                return Object.keys(OrderType);
            }
        }
    }

</script>

<style lang="scss" scoped>

    .create-pending-order-dialog {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

    .dates {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
    }

    .el-select {
        width: 80%;
    }

    .pending-order-info {
        width: 100%;
        margin: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

    .pending-order-info-row {
        width: 100%;
        margin-bottom: 10px;
        border: 2px solid $default-primary-title-text-color;
        border-radius: 5px;
    }

    .pending-order-info-order-row {
        width: 100%;
        margin-bottom: 10px;
    }

    .pending-order-info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
    }

    .title {
        font-weight: bold;
        margin-bottom: 5px;
    }

    .footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        background-color: $dialog-background-color !important;
        margin-right: 50px;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .continue {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: $dialog-background-color !important;
        margin-top: 20px;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 20px;
    }

    .table-tag {
        width: 90%;
    }

    .close-label {
        color: $default-primary-title-text-color !important;
    }

    .pending-order-info-type-title {
        font-weight: bold;
        font-size: 14px;
        margin-top: 5px;
        color: $default-primary-title-text-color !important;
    }

    .pending-order-info-type {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .pending-order-info-order {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border: 2px solid $default-primary-title-text-color;
        border-radius: 5px;
    }

    @media screen and (max-width: 1024px) {

        .dates {
            width: 80%;
        }

        .dialog-confirm {
            width: 30%;
        }

        .dialog-close {
            width: 30%;
        }

        .pending-order-info-row {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin: 2px;
        }

        .pending-order-info-order-row {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }

        .pending-order-info-order {
            margin: 2px;
        }

        .el-input-number {
            width: 100% !important;
        }

        .price-input {
            width: 100% !important;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .dates {
            width: 30%;
        }

        .dialog-confirm {
            width: 15%;
        }

        .dialog-close {
            width: 15%;
        }

        .pending-order-info-row {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            margin: 5px;
            padding: 5px;
        }

        .pending-order-info-order-row {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
        }

        .pending-order-info-order {
            margin: 5px;
            padding: 5px;
        }

        .el-input-number {
            width: 80% !important;
        }

    }

</style>
